import { HTMLAttributes, Ref, createElement, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { CustomMarkdown } from '@components';
import { FontVariant, FontVariantProps, getElementName, textTypography } from '@utils';

export interface TextWithMdProps extends HTMLAttributes<HTMLHeadingElement> {
  variant?: FontVariant;
  onClick?: (() => Promise<void>) | (() => void);
  onLinkClick?: () => void;
}

export const TextWithMd = forwardRef<HTMLElement, TextWithMdProps>(
  (
    { variant = 'body-16/r', className, children, onClick, onLinkClick, ...rest },
    ref: Ref<HTMLElement>,
  ) => {
    const [sizeVariant, weightVariant] = variant.split('/') as [
      FontVariantProps['variant'],
      FontVariantProps['weight'],
    ];

    const textVariant = textTypography({
      variant: sizeVariant,
      weight: weightVariant,
    });

    const newClassName = twMerge(textVariant, className);

    const elementName = getElementName(sizeVariant);

    return createElement(
      elementName,
      {
        className: newClassName,
        ref,
        onClick,
        ...rest,
      },
      <CustomMarkdown onLinkClick={onLinkClick}>{children as string}</CustomMarkdown>,
    );
  },
);

TextWithMd.displayName = 'TextWithMd';
