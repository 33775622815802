import React from 'react';
import { twMerge } from 'tailwind-merge';

import { getRegionSelector, useRegionStore } from '@packages/stores';
import { Boost } from '@packages/types';
import { getBoostModalType, isBoostAdded, isPayPerDayBoost } from '@packages/utils';

import { BoostPrice, Text, TextProps } from '@components';
import { Button } from '@elements/button';
import { Notification } from '@elements/notification';
import { i18n } from '@i18n';
import { useTripStore } from '@store';
import { testProps } from '@utils';

import { BoostModalHeader } from '../boostModalHeader';

function getBoostButtonTextId(boost: Boost) {
  const modalType = getBoostModalType(boost);
  const showEditBoostText = isBoostAdded(boost);
  switch (modalType) {
    case 'addSpecifiedItems':
      return showEditBoostText
        ? i18n.t('boostModals.specifiedItems.button')
        : i18n.t('boostDetails.extraCancellationModal.actions.addItem');
    default:
      return i18n.t('boostDetails.extraCancellationModal.actions.addTrip');
  }
}

export interface BoostModalContainerProps {
  children?: React.ReactNode;
  boost?: Boost;
  onSubmit: () => Promise<void> | void;
  titleProps?: TextProps;
  isBoostModalFooterDisabled?: boolean;
  isLoading?: boolean;
  isCruisePopUp?: boolean;
}

export function BoostModalContainer({
  onSubmit,
  boost,
  children,
  titleProps,
  isBoostModalFooterDisabled = false,
  isLoading = false,
  isCruisePopUp = false,
}: BoostModalContainerProps) {
  const region = useRegionStore(getRegionSelector);
  const isBoostUpdating = useTripStore(
    state => !!state?.trip?.boosts?.find(it => it.boostId === boost?.boostId)?.toggleStatus,
  );
  if (!boost) {
    return null;
  }
  const showPayPerDayNotification = isPayPerDayBoost(boost);
  const notificationTitleId = isCruisePopUp
    ? 'boostShared.payPerDayModal.cruiseCoverPopUp.notificationTitle'
    : 'boostShared.payPerDayModal.notification.title';

  const notificationBody = isCruisePopUp
    ? undefined
    : i18n.t('boostShared.payPerDayModal.notification.body');

  return (
    <div className="flex flex-1 flex-col pt-4">
      <div className="overflow-y-auto max-h-[70vh] px-4 my-6 no-scrollbar sm:px-8">
        <BoostModalHeader boost={boost} isCruisePopUp={isCruisePopUp} />
        <div
          className={twMerge(
            'flex flex-col mt-s8 my-2 gap-s8',
            isCruisePopUp && 'flex-col-reverse',
          )}>
          {region?.country === 'AU' && showPayPerDayNotification && (
            <Notification
              variant="info"
              header={i18n.t(notificationTitleId)}
              body={notificationBody}
            />
          )}

          <Text
            variant="h2-36/sb"
            {...titleProps}
            className={twMerge(titleProps?.className)}
            {...testProps('boost-modal-title')}
          />
        </div>
        {children}
      </div>
      <div className="bg-cabo-50 flex flex-row items-center rounded-b-2xl p-8">
        <div className="flex flex-[5] md:flex-[6]">
          {boost && <BoostPrice boost={boost} type="boostModal" />}
        </div>
        <div className="flex flex-[5] justify-end  text-center md:flex-[4]">
          <Button
            {...testProps(`get-boost-modal-button-save`)}
            onClick={onSubmit as () => void}
            disabled={isBoostModalFooterDisabled}
            type="submit"
            variant="primary"
            className="w-full"
            isLoading={isBoostUpdating || isLoading}
            title={getBoostButtonTextId(boost)}
          />
        </div>
      </div>
    </div>
  );
}
