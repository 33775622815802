import {
  createQuote as createQuoteApi,
  sendEmailQuote as sendEmailQuoteApi,
  updateQuote as updateQuoteApi,
} from '@packages/api';

import { GetStore, getRegionState, useConfigStore } from '@packages/stores';
import { EmailQuoteDetails, Trip } from '@packages/types';
import {
  getEmcBoost,
  getEmcRegionsFromDestinations,
  getVeriskScreeningParametersForRedeclare,
} from '@packages/utils';

import { captureException, withScope } from '@sentry/react';
import { TripStore, useTripStore } from '@store/trip';
import { getClickIdAnonymousId, growsurfApi, sentryTags } from '@utils';

import { useQuoteStore } from './quote.store';

export const createQuote = async (get: GetStore<TripStore>): Promise<Trip | undefined> => {
  return await withScope(async scope => {
    sentryTags({ event: 'create.quote', source: 'createQuote' });

    const { setIsCreatingOrUpdatingTrip, setTrip, setPending, setError, trip } = get();
    const { quoteLoadingType, setQuoteLoadingType } = useQuoteStore.getState();
    const region = getRegionState();
    const defaultProductId =
      useConfigStore.getState().regionSpecificConfig?.DEFAULT_PRODUCT_ID ?? '';
    const referralParticipantId = growsurfApi.getReferrerId();
    const campaignId = await growsurfApi.getCampaignId();

    if (!trip) {
      throw new Error('Trip is not defined');
    }

    if (!region) {
      return;
    }

    const tripPayload: Trip = {
      ...trip,
      productId: defaultProductId,
      ...(region.country === 'US' && {
        us: {
          state: region.state,
          totalTripCost: trip.totalTripCost,
          addCancellationBoost: !!trip?.additionalProperties?.addCancellationBoost,
        },
      }),
      referralParticipantId,
      referralCampaignId: campaignId,
    };

    scope.setTag('tripPayload', JSON.stringify(tripPayload));

    try {
      setPending(true);
      setIsCreatingOrUpdatingTrip(true);
      setQuoteLoadingType({
        type: 'quoteLoading',
      });

      const { trip: createdTrip } = await createQuoteApi({
        trip: tripPayload,
        region: region,
        isAuthenticated: false,
        withTrip: true,
      });

      setTrip(createdTrip);
      return createdTrip;
    } catch (err: any) {
      captureException(err as Error);
      setError(err as Error);
      const isTimeoutError = err?.message === 'Timeout error';
      const showQuoteError = !isTimeoutError || quoteLoadingType.quoteRetryCount >= 1;
      const quoteRetryCount = isTimeoutError
        ? quoteLoadingType.quoteRetryCount + 1
        : quoteLoadingType.quoteRetryCount;

      setQuoteLoadingType({
        quoteRetryCount: quoteRetryCount,
        type: showQuoteError ? 'quoteError' : 'quoteRetry',
      });
    } finally {
      setPending(false);
      setIsCreatingOrUpdatingTrip(false);
    }
  });
};

export const updateQuote = async (get: GetStore<TripStore>, quiet?: boolean) => {
  return await withScope(async scope => {
    sentryTags({ event: 'update.quote', source: 'updateQuote' });
    const { setIsCreatingOrUpdatingTrip, getTrip, setPending, setError, trip } = get();
    const { quoteLoadingType, setQuoteLoadingType } = useQuoteStore.getState();
    const region = getRegionState();
    const referralParticipantId = growsurfApi.getReferrerId();
    const campaignId = await growsurfApi.getCampaignId();

    if (!trip) {
      throw new Error('Trip is not defined');
    }

    if (!region) {
      return;
    }

    try {
      if (!region?.country) {
        throw new Error('No region selected');
      }
      setPending(true);
      setError(undefined);
      setIsCreatingOrUpdatingTrip(true);
      setQuoteLoadingType({
        type: 'quoteLoading',
      });
      const quotePayload: Trip = {
        ...trip,
        destinations: trip.destinations?.map(({ countryCode, countryId, longName }) => ({
          countryCode,
          countryId,
          longName,
        })),
        ...(region.country === 'US' && {
          us: {
            state: region.state,
            totalTripCost: trip.totalTripCost,
            addCancellationBoost: !!trip?.additionalProperties?.addCancellationBoost,
          },
        }),
        referralParticipantId,
        referralCampaignId: campaignId,
        ...(quiet
          ? {
              screeningParameters: getVeriskScreeningParametersForRedeclare({
                env: import.meta.env.VITE_ENV,
                regionIds: getEmcRegionsFromDestinations(trip.destinations),
                tripDuration: getEmcBoost(trip)?.duration,
              }),
            }
          : {}),
      };
      scope.setTag('quotePayload', JSON.stringify(quotePayload));
      await updateQuoteApi({
        quote: quotePayload,
        region: region,
        isAuthenticated: false,
      });
      return await getTrip();
    } catch (error: any) {
      captureException(error);
      setError(error as Error);
      const isTimeoutError = error?.message === 'Timeout error';
      const showQuoteError = !isTimeoutError || quoteLoadingType.quoteRetryCount >= 1;
      const quoteRetryCount = isTimeoutError
        ? quoteLoadingType.quoteRetryCount + 1
        : quoteLoadingType.quoteRetryCount;

      setQuoteLoadingType({
        quoteRetryCount: quoteRetryCount,
        type: showQuoteError ? 'quoteError' : 'quoteRetry',
      });
    } finally {
      setPending(false);
      setIsCreatingOrUpdatingTrip(false);
    }
  });
};

export const sendEmailQuote = async (
  emailQuoteDetails: EmailQuoteDetails,
  triggerType: 'emailQuote' | 'abandonCart' = 'emailQuote',
) => {
  return await withScope(async scope => {
    const trip = useTripStore.getState().trip;
    scope.setTag('quoteId ', trip.metaData?.quoteId);
    sentryTags({ event: 'submit.emailQuote', source: 'sendEmailQuote' });
    const region = getRegionState();
    const { clickId, anonymousId } = getClickIdAnonymousId();
    const emailQuoteData = { ...emailQuoteDetails, clickId, anonymousId };
    if (!trip) {
      throw new Error('Trip is not defined');
    }

    if (!region) {
      throw new Error('Region is not defined');
    }
    try {
      const response = await sendEmailQuoteApi({
        emailQuoteDetails: emailQuoteData,
        quoteId: trip.metaData?.quoteId ?? '',
        region: region,
        triggerType,
      });
      return response && 'Success';
    } catch (error: any) {
      captureException(error);
      if (error.response.status === 400 && error.response.data && error.response.data.message) {
        if (error.response.data.message.includes('limit')) return 'Limit';
        else return 'Failed';
      }
    }
  });
};
