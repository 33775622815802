import { FC } from 'react';
import ReactMarkdown, { Options } from 'react-markdown';
import rhypeRaw from 'rehype-raw';

export const CustomMarkdown: FC<Options & { onLinkClick?: () => void }> = ({
  children,
  onLinkClick,
  ...rest
}) => {
  return (
    <ReactMarkdown
      {...rest}
      components={{
        a: ({ href, ...props }) => (
          <a
            className="underline"
            href={href}
            onClick={e => {
              if (onLinkClick) {
                e.preventDefault();
                onLinkClick();
              }
            }}
            {...props}
            target="_blank"
            rel="noreferrer"
          />
        ),
        p: ({ children }) => <>{children}</>,
      }}
      rehypePlugins={[rhypeRaw]}>
      {children}
    </ReactMarkdown>
  );
};
