import ENV_VARS_CONSTANTS from 'freely-shared-env-vars/envVars.json';
import { getRemoteSettings, openApiInstances } from '@packages/open-api';

import { GetStore } from '../utils/utils';
import { ConfigStore } from './config.store';

/**
 * Abstracting the logic by creating utils for the store.
 * This function takes in a getter callback that returns the configState.
 * From this callback you can use the actions accordingly.
 * @param get
 */
const env: 'dev' | 'staging' | 'prod' = (import.meta as any).env.VITE_ENV;
openApiInstances.Config.defaults.baseURL =
  ENV_VARS_CONSTANTS.CONFIGURATION_SERVICE_API_URL[env] + `/${env}`;

openApiInstances.Config.defaults.headers.common['x-api-key'] =
  ENV_VARS_CONSTANTS.CONFIGURATION_SERVICE_API_KEY[env];

openApiInstances.Config.defaults.headers.common['x-app-version'] =
  ENV_VARS_CONSTANTS.APP_VERSION[env];

openApiInstances.Config.defaults.headers.common['platform'] = 'web';

export const fetchConfig = async (get: GetStore<ConfigStore>) => {
  const { setPending, setError, setConfig } = get();
  try {
    // Set whatever action to reflect the promise lifecycle
    setPending(true);
    const { data } = await getRemoteSettings();
    setConfig(data);
    return data;
  } catch (error) {
    setError(error as Error);
    throw error;
  } finally {
    setPending(false);
  }
};
