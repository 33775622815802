import { useParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { shallow } from 'zustand/shallow';

import { getRegionSelector, useRegionStore } from '@packages/stores';
import { getBoostTagNameAndVariant, isBoostEligible } from '@packages/utils';

import { Container } from '@components/container';
import { Badge } from '@elements/badge';
import { getSelectedBoostById, useBoostDetailsStore, useTripStore } from '@store';
import { sluggify, testProps } from '@utils';

import { Text } from '../text';
import { BoostHeaderActions } from './boostHeaderActions.component';

export const BoostHeader = () => {
  const params = useParams();
  const boostId = params?.id ?? '';

  const [title, boostCategoryCaption, description] = useBoostDetailsStore(state => {
    const mandatoryBoostId = state?.mandatoryBoostDetails?.boostId;
    const details = mandatoryBoostId === boostId ? state.mandatoryBoostDetails : state.boostDetails;
    return [
      details?.template?.title,
      details?.template?.categoryCaption,
      details?.template?.description,
    ];
  }, shallow);
  const selectedBoost = useTripStore(getSelectedBoostById(boostId));

  const region = useRegionStore(getRegionSelector);

  const isPreTripCancellationEligible = isBoostEligible(selectedBoost, region);

  const badgeTagProps = getBoostTagNameAndVariant(selectedBoost);

  return (
    <div
      className={twMerge(
        'bg-nusa-100 w-full py-s32 ',
        !isPreTripCancellationEligible && '!bg-fuji-50',
      )}>
      <Container className="space-y-2 lg:space-y-3">
        {badgeTagProps ? <Badge {...badgeTagProps} className="mb-s8" /> : null}
        <Text
          variant="body-16/r"
          className="text-fuji-800 uppercase"
          {...testProps(`boost-category-${sluggify(boostCategoryCaption)}`)}>
          {boostCategoryCaption}
        </Text>
        <Text
          variant={{
            lg: 'h2-36/sb',
            md: 'h2-36/sb',
            sm: 'h2-36/sb',
          }}
          className="text-fuji-800 h2-36/sb"
          {...testProps('boost-title')}>
          {title}
        </Text>
        <Text
          variant="h4-24/r"
          className="text-fuji-800 w-full pb-2 lg:w-9/12"
          {...testProps('boost-description')}>
          {description}
        </Text>
        <BoostHeaderActions />
      </Container>
    </div>
  );
};
