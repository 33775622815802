import { Navigate, RouteObject, createBrowserRouter } from 'react-router-dom';

import { getRegionCountry } from '@packages/stores';

import { DefaultFallback } from '@components/errorBoundary';
import * as Sentry from '@sentry/react';
import {
  BoostDetail,
  Checkout,
  Confirmation,
  Debug,
  Destinations,
  Home,
  LoadQuote,
  MyQuote,
  NotFound,
  Quote,
  StateOfResidence,
  TravelDates,
  Travellers,
  TripCost,
} from '@views';

export enum ROUTES {
  STATE_OF_RESIDENCE = 'state-of-residence',
  QUOTE = 'quote',
  LOAD_QUOTE = 'load',
  //trip creation flow
  TRIP_DESTINATIONS = 'trip-destinations',
  TRIP_DATES = 'trip-dates',
  TRIP_TRAVELLERS = 'trip-travellers',
  TRIP_COST = 'trip-cost',
  TRIP_DEPOSIT_DATE = 'trip-deposit-date',
  //boost flow
  MY_QUOTE = 'my-quote', // 1-page quote
  BOOST_DETAILS = 'boost-details',
  //payment flow
  CHECKOUT = 'checkout',
  CONFIRMATION = 'confirmation',
}

export const ROUTE_PATHS = () => {
  const country = getRegionCountry()?.toLowerCase();
  return {
    confirmation: `/quote/${country}/confirmation`,
    checkout: `/quote/${country}/checkout`,
    boostDetails: (boostId: string) => `/quote/${country}/boost-details/${boostId}`,
    myQuote: `/quote/${country}/my-quote`,
    tripCost: `/quote/${country}/trip-cost`,
    tripTravellers: `/quote/${country}/trip-travellers`,
    tripDates: `/quote/${country}/trip-dates`,
    tripDestinations: `/quote/${country}/trip-destinations`,
    loadQuote: `/quote/${country}/load`,
    stateOfResidence: `/quote/${country}/state-of-residence`,
    travellerDetails: `/quote/${country}/traveller-details`,
  };
};

const routes: RouteObject[] = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: ROUTES.QUOTE,
    element: <Navigate to="/" />,
  },
  {
    path: `${ROUTES.QUOTE}/:region`,
    element: <Quote />,
    children: [
      {
        path: ROUTES.LOAD_QUOTE,
        element: <LoadQuote />,
      },
      {
        path: ROUTES.STATE_OF_RESIDENCE,
        element: <StateOfResidence />,
      },
      {
        path: ROUTES.MY_QUOTE,
        element: <MyQuote />,
      },
      {
        path: `${ROUTES.BOOST_DETAILS}/:id`,
        element: <BoostDetail />,
      },

      {
        path: ROUTES.TRIP_DATES,
        element: <TravelDates />,
      },
      {
        path: ROUTES.TRIP_TRAVELLERS,
        element: <Travellers />,
      },
      {
        path: ROUTES.CHECKOUT,
        element: <Checkout />,
      },
      {
        path: ROUTES.TRIP_COST,
        element: <TripCost />,
      },
      {
        path: ROUTES.TRIP_DESTINATIONS,
        element: <Destinations />,
      },
      {
        path: ROUTES.CONFIRMATION,
        element: <Confirmation />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
];

if (import.meta.env.MODE !== 'prod') {
  routes.push({
    path: 'debug',
    element: <Debug />,
  });
}

const routesWithErrorBoundary = routes.map(route => ({
  ...route,
  errorElement: <DefaultFallback />,
}));

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
export const router = sentryCreateBrowserRouter(routesWithErrorBoundary);

export const navigateToFirstRegionView = () => {
  const country = getRegionCountry()?.toLowerCase();
  const routePaths = ROUTE_PATHS();
  if (country === 'au') {
    router.navigate(routePaths.tripDestinations);
  }
  if (country === 'us') {
    router.navigate(routePaths.stateOfResidence);
  }
};

export function getCurrentPathname() {
  return router?.state?.location?.pathname;
}

export function redirectToMyQuote() {
  const myQuoteScreenUrl = ROUTE_PATHS().myQuote;
  if (getCurrentPathname() !== myQuoteScreenUrl) {
    router.navigate(myQuoteScreenUrl);
  }
}
