/**
 * A function that clears session storage.
 * When we clear session storage, we want to retain the CognitoIdentityId.
 */
export function clearSessionStorage() {
  const cognitoIdentityIdKey = Object.keys(window.sessionStorage).find(i =>
    i.startsWith(`com.amplify.Cognito.${import.meta.env.VITE_AWS_REGION ?? 'ap-southeast-2'}`),
  );
  const cognitoIdentityId = cognitoIdentityIdKey
    ? window.sessionStorage.getItem(cognitoIdentityIdKey)
    : '';

  window.sessionStorage.clear();

  if (cognitoIdentityId && cognitoIdentityIdKey) {
    window.sessionStorage.setItem(cognitoIdentityIdKey, cognitoIdentityId);
  }
}

export function clearIdentityId() {
  const cognitoIdentityIdKey = Object.keys(window.sessionStorage).find(i =>
    i.startsWith(`com.amplify.Cognito.${import.meta.env.VITE_AWS_REGION ?? 'ap-southeast-2'}`),
  );
  const cognitoIdentityId = cognitoIdentityIdKey
    ? window.sessionStorage.getItem(cognitoIdentityIdKey)
    : '';

  if (cognitoIdentityId && cognitoIdentityIdKey) {
    window.sessionStorage.removeItem(cognitoIdentityIdKey);
  }
}
