import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import {
  boostSharedAU,
  emcSharedAssessmentAU,
  emcSharedGlobalAU,
  emcSharedInformationAU,
  emcSharedPreAssessmentAU,
  emcSharedScreeningResultAU,
  errorsAU,
  errorsUS,
  globalSharedAU,
  globalSharedUS,
} from '@packages/i18n-source';

import { captureException, withScope } from '@sentry/react';
import { sentryTags } from '@utils';

import {
  addBoostAU,
  boostDetailsAU,
  boostDetailsUS,
  boostModalsAU,
  boostModalsUS,
  checkoutAU,
  checkoutUS,
  destinationNoteAU,
  emailQuoteAU,
  footerAU,
  footerUS,
  globalAU,
  globalUS,
  maintenanceModalAU,
  maintenanceModalUS,
  myQuoteAU,
  myQuoteUS,
  selectDestinationsAU,
  selectDestinationsUS,
  stateOfResidenceUS,
  stopSellAU,
  stopSellUS,
  travelDatesAU,
  travelDatesUS,
  travellerFormAU,
  travellerFormUS,
  travellersAU,
  travellersUS,
  tripCostUS,
} from './locales';

export const i18n = i18next.createInstance();

i18n.use(initReactI18next).init({
  debug: process.env.NODE_ENV === 'development',
  resources: {
    'en-AU': {
      translation: {
        checkout: checkoutAU,
        boostShared: boostSharedAU,
        globalShared: globalSharedAU,
        emcShared: {
          global: emcSharedGlobalAU,
          information: emcSharedInformationAU,
          preAssessment: emcSharedPreAssessmentAU,
          assessment: emcSharedAssessmentAU,
          screeningResult: emcSharedScreeningResultAU,
        },
        global: globalAU,
        selectDestinations: selectDestinationsAU,
        travelDates: travelDatesAU,
        travellers: travellersAU,
        myQuote: myQuoteAU,
        boostDetails: boostDetailsAU,
        destinationNote: destinationNoteAU,
        boostModals: boostModalsAU,
        travellerForm: travellerFormAU,
        emailQuote: emailQuoteAU,
        errors: errorsAU,
        footer: footerAU,
        stopSell: stopSellAU,
        maintenanceModal: maintenanceModalAU,
        addBoost: addBoostAU,
      },
    },
    'en-US': {
      translation: {
        checkout: checkoutUS,
        global: globalUS,
        globalShared: globalSharedUS,
        footer: footerUS,
        selectDestinations: selectDestinationsUS,
        stateOfResidence: stateOfResidenceUS,
        travelDates: travelDatesUS,
        travellers: travellersUS,
        tripCost: tripCostUS,
        errors: errorsUS,
        myQuote: myQuoteUS,
        boostDetails: boostDetailsUS,
        boostModals: boostModalsUS,
        travellerForm: travellerFormUS,
        stopSell: stopSellUS,
        maintenanceModal: maintenanceModalUS,
      },
    },
  },
  lng: 'en-AU',
  fallbackLng: 'en-AU',
  missingKeyHandler(lngs, _, key) {
    withScope(() => {
      sentryTags({ event: 'missing.translation', source: 'i18next' });
      captureException(new Error(`i18next:: ${lngs} Missing translation for ${key}`));
    });
  },
  saveMissing: true,
});
