import currency from 'currency.js';
import { CountryCode, validatePhoneNumberLength } from 'libphonenumber-js';
import lowerCase from 'lodash/lowerCase';
import type { DateTime } from 'luxon';
import { z } from 'zod';

import { REGIONS } from '@packages/constants';
import {
  PhoneNumberValidationRule,
  RegionValidationRules,
  SecondaryTraveller,
  TripDestination,
} from '@packages/types';

import {
  getAge as getAgeForWeb,
  getDurationOfDatesInDays,
  getRegionDateTime,
  isInPast as isInPastForWeb,
  parseISO,
} from '../datetime/datetime';
import { CurrencyCodeISOList } from '../i18n/i18n';
import { deepCompareTripDestinations } from '../trip/common';

const countries = Object.values(REGIONS);

const fieldSchemas = {
  firstName: z
    .string()
    .min(1, { message: 'Please enter a valid first name.' })
    .regex(/^(?!.*--)[a-zA-Z_’'\s-]+$/, { message: 'Please enter a valid first name.' }),
  lastName: z
    .string()
    .min(1, { message: 'Please enter a valid last name.' })
    .regex(/^(?!.*--)[a-zA-Z_’'\s-]+$/, { message: 'Please enter a valid last name.' }),
  name: z
    .string()
    .min(1, { message: 'Please enter a valid name.' })
    .regex(/^(?!.*--)[a-zA-Z_’'\s-]+$/, { message: 'Please enter a valid name.' }),
  cardHolderName: z
    .string()
    .min(2, { message: '^Minimum of 2 characters for cardholder name.' })
    .regex(/^(?!.*--)[a-zA-Z_’'\s-]+$/, {
      message: 'Please provide the name as it appears on your card.',
    }),
  email: z
    .string()
    .email({ message: 'Please enter a valid email address.' })
    .regex(/^(?!.*\.con$).*$/, { message: '^Email must end with .com' }),
  password: z.string().min(1, { message: 'Please enter your password.' }),
  onboardingPassword: z
    .string()
    .min(8, { message: 'Password must be at least 8 characters.' })
    .max(64)
    .regex(/^(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*]).{8,64}$/, {
      message: 'Password must contain at least one letter and number and one symbol from !@#$% .',
    }),
  verificationCode: z
    .string()
    .min(6, { message: 'Verification code must be a 6 digit number.' })
    .regex(/^[0-9]+$/, { message: 'Please enter a valid verification code.' }),
  signInPassword: z.string().min(1, { message: 'Please enter your password.' }),
  addressLine: z.string().min(1, { message: 'Address is required.' }),
  addressLine2: z
    .string()
    .regex(/^(?:\s*\S.*|)$/, { message: 'Address line 2 is invalid.' })
    .optional(),

  claimCountry: z
    .string()
    .min(1, { message: 'Please enter the country.' })
    .regex(
      /^[^0-9\u00a9\u00ae\u2000-\u3300\ud83c\ud000-\udfff\ud83d\ud000-\udfff\ud83e\ud000-\udfff]*$/,
      { message: 'Please enter a valid country.' },
    ),
  boostSpecifiedItemDescription: z
    .string()
    .min(1, { message: 'Please enter item name or description.' }),
  profileDOB: z.string().min(1, { message: 'Please enter date of birth.' }),
};

export const validate = (fieldName: keyof typeof fieldSchemas, value?: string) => {
  const schema = fieldSchemas[fieldName];
  const result = schema.safeParse(value);
  if (!result.success) {
    return result.error.errors?.[0]?.message;
  }
  return;
};

export const validateState = (countryCode: string, state?: string) => {
  const countryInfo = countries.find(it => countryCode === it.code);
  if (countryInfo) {
    const result = countryInfo.states.some(it => it.code === state?.toUpperCase());
    if (result) {
      return;
    } else {
      return `Valid state are ${countryInfo.states.map(item => ` ${item.code}`)}`;
    }
  }
  return;
};

export const validatePostCode = (rules: RegionValidationRules, postCode?: string) => {
  if (rules) {
    const regex = new RegExp(rules.postcodeRegex);
    const result = regex.exec(postCode ?? '');
    if (result === null) {
      return rules.postCodeRegexMessage;
    }
  }
  return;
};

export const validateCurrencyByCountry = (countryCurrencySymbol: string, currency?: string) => {
  if (!currency) {
    return 'Please choose the currency you would like to claim.';
  }
  if (countryCurrencySymbol) {
    if (countryCurrencySymbol.toUpperCase() !== currency.toUpperCase()) {
      return `Valid currency is ${countryCurrencySymbol}.`;
    } else {
      return;
    }
  }
  return `Country currency symbol ${countryCurrencySymbol} not found.`;
};

export const validateCurrency = (currency?: string) => {
  if (!currency) {
    return 'Please choose the currency you would like to claim.';
  }
  const result = CurrencyCodeISOList.indexOf(currency.toUpperCase()) >= 0;
  if (result) {
    return null;
  } else {
    return `Currency ${currency} is invalid.`;
  }
};

export const validatePhoneNumber = (
  rules:
    | Pick<PhoneNumberValidationRule, 'phoneNumberRegex' | 'phoneNumberRegexMessage'>
    | undefined,
  phoneNumber: string | undefined,
  errorMessage?: string,
) => {
  if (!phoneNumber) {
    return 'Please enter a valid phone number.';
  }
  if (!rules) {
    return 'Validation rules not found.';
  }
  const regex = new RegExp(rules.phoneNumberRegex);
  const result = regex.exec(phoneNumber.replace(/\s/g, ''));
  if (result === null) {
    return errorMessage ?? rules.phoneNumberRegexMessage;
  }
  return undefined;
};

export const validateIntlPhoneNumber = (
  phone: string,
  countryCode: CountryCode,
  rule?: Pick<PhoneNumberValidationRule, 'phoneNumberRegex' | 'phoneNumberRegexMessage'>,
) => {
  if (rule) {
    return validatePhoneNumber(rule, phone);
  }
  const result = validatePhoneNumberLength(phone, countryCode);
  return result ? `Phone number error: ${lowerCase(result)}` : undefined;
};

export const validateEmojis = (fieldName: string, value: string | null | undefined) => {
  if (!value) {
    return `${fieldName}`;
  }
  const regex = new RegExp(
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/,
  );
  const result = regex.exec(value);
  if (result === null) {
    return;
  }
  return 'Emoji is not allowed.';
};

export function dateIsValid(dateStr: string | undefined) {
  if (!dateStr) {
    return false;
  }
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if (dateStr.match(regex) === null) {
    return false;
  }
  const date = new Date(dateStr);
  const timestamp = date.getTime();
  if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
    return false;
  }
  return date.toISOString().startsWith(dateStr);
}

export const validateDate = (value: string | undefined, customMessage?: string) => {
  if (!dateIsValid(value)) {
    return customMessage ?? 'Invalid Date';
  }
  return;
};

export function validateDestinations(destinations: TripDestination[], maxDestinations = 10) {
  const isWithinLimit = destinations.length > 0 && destinations.length <= maxDestinations;
  if (destinations.length === 0 || !destinations.length) {
    return 'Please select at least one destination';
  }
  if (!isWithinLimit) {
    return 'Reached max destinations';
  }
  return;
}

export const primaryAgeValidation = (
  dateOfBirth: DateTime | string | undefined,
  { max = 99, min = 18, country = REGIONS.AU.code } = {},
  customMessage?: string,
) => {
  const age = dateOfBirth ? getAgeForWeb(dateOfBirth, country) : 0;
  if (age === undefined) {
    return 'Incorrect age!';
  }
  if (age < min || age > max) {
    return customMessage ?? `You must be between ${min} and ${max} years of age.`;
  }
  return;
};

export const secondaryAgeValidation = (
  dateOfBirth: string | undefined,
  { max = 99, min = 0, country = REGIONS.AU.code } = {},
  customMessage?: string,
) => {
  if (dateOfBirth && !isInPastForWeb(dateOfBirth, country)) {
    return 'Date of birth must be in the past!';
  }
  if (!dateOfBirth) {
    return 'Please provide a date of birth.';
  }
  const age = dateOfBirth ? getAgeForWeb(dateOfBirth, country) : 0;
  let regionSpecificTraveller = 'Traveller';
  if (country === REGIONS.US.code) {
    regionSpecificTraveller = 'Traveler';
  }
  if (age < min) {
    return customMessage ?? `${regionSpecificTraveller} cannot be under ${min} year of age.`;
  }
  if (age > max) {
    return customMessage ?? `${regionSpecificTraveller} cannot be over ${max} years of age.`;
  }
  return;
};

export const validateExpiryDateWeb = (
  expiryDate: string,
  currentDate: DateTime,
  errorMessageIncomplete = "Your card's expiration date is incomplete.",
  errorMessagePast = "Your card's expiration year is in the past.",
) => {
  if (!expiryDate) {
    return errorMessageIncomplete;
  }
  if (!/^\d{2}\/\d{2}$/.test(expiryDate)) {
    return errorMessageIncomplete;
  }
  const [month, year] = expiryDate.split('/');
  if (!month || !year) {
    return errorMessageIncomplete;
  }
  const monthInt = parseInt(month, 10);
  const yearInt = parseInt(currentDate.year.toString().substring(0, 2) + year, 10);
  if (monthInt < 1 || monthInt > 12) {
    return errorMessageIncomplete;
  }
  if (
    yearInt < currentDate.year ||
    (yearInt === currentDate.year && monthInt < currentDate.month)
  ) {
    return errorMessagePast;
  }
  return true;
};

export const validateExpiryDate = (
  expiryDate: string,
  currentDate: DateTime,
  errorMessage = 'Expired',
) => {
  if (!expiryDate) {
    return errorMessage;
  }
  if (expiryDate.length !== 5) {
    return errorMessage;
  }
  const [month, year] = expiryDate.split('/');
  const monthInt = parseInt(month ?? '', 10);
  if (monthInt > 12 || monthInt < 1) {
    return errorMessage;
  }
  const yearInt = parseInt(currentDate.year.toString().substring(0, 2) + year, 10);
  if (
    yearInt < currentDate.year ||
    (yearInt === currentDate.year && monthInt < currentDate.month)
  ) {
    return errorMessage;
  }
  return;
};

export const validateRegionSpecificPhoneNumber = (
  phoneNumber: string,
  isUS: boolean,
  prefix: string | undefined,
) => {
  if (!(isUS ? /^\d{10}$/g : /^\d{9}$/g).test(phoneNumber)) {
    return `Incorrect phone number. Please provide a correct number eg. ${
      isUS ? `${prefix} 844-246-8480` : `${prefix} 412345678`
    }`;
  }
  return;
};

export const validateDestinationsSubmitButton = ({
  isModal,
  formTripDestinations,
  tripDestinations,
}: {
  isModal?: boolean;
  formTripDestinations: TripDestination[];
  tripDestinations: TripDestination[];
}) => {
  if (isModal) {
    const canEdit = !deepCompareTripDestinations(formTripDestinations, tripDestinations);
    if (!canEdit) {
      return 'Destinations not changed';
    }
  }
  return;
};

type ValidateTravelDatesSubmitButton = {
  isModal?: boolean;
  startDate: string;
  endDate: string;
  tripStartDate: string;
  tripEndDate: string;
};

export function validateTravelDatesSubmitButton({
  isModal,
  endDate,
  tripEndDate,
  tripStartDate,
  startDate,
}: ValidateTravelDatesSubmitButton) {
  const canProgress = !!startDate && !!endDate;
  const canUpdate = startDate !== tripStartDate || endDate !== tripEndDate;
  if (isModal && !canUpdate) {
    return 'trip dates not changed';
  }
  if (!canProgress) {
    return 'trip dates not selected';
  }
  return;
}

export function validateTripCostButton({
  tripCost,
  formTripCost,
  isModal,
}: {
  isModal?: boolean;
  tripCost?: string | number | null;
  formTripCost?: string | number;
}) {
  const tripCostValue = currency(tripCost ?? 0).intValue;
  const formTripCostValue = currency(formTripCost ?? 0).intValue;
  if (isModal && tripCostValue === formTripCostValue) {
    return 'trip cost not changed';
  }
  if (!formTripCostValue) {
    return 'trip cost not entered';
  }
  return;
}

export function validateResidencyCheckBox({
  formPrimaryTravellerIsResident,
  formSecondaryTravellers,
}: {
  formPrimaryTravellerIsResident: boolean;
  formSecondaryTravellers?: SecondaryTraveller[];
}) {
  if (!formPrimaryTravellerIsResident) {
    return 'Please confirm that all travellers are residents';
  }
  if (formSecondaryTravellers?.length && formSecondaryTravellers.some(it => !it.isResident)) {
    return 'Please confirm that all secondary travellers are residents';
  }
  return;
}

export function shouldRevalidateDependencyFlag(
  formattedDate: string,
  residentCheckBoxStatus: boolean,
) {
  return !validateDate(formattedDate) && residentCheckBoxStatus;
}

export function getMaxAllowedTripCost({
  shortNoticeCostPerTraveller,
  longNoticeCostPerTraveller,
  numberOfTravelers,
  departureDate,
  tripCost,
  noticeDays = 5,
}: {
  shortNoticeCostPerTraveller: number;
  longNoticeCostPerTraveller: number;
  departureDate: string;
  numberOfTravelers: number;
  tripCost: number;
  noticeDays?: number;
}) {
  const departure = parseISO(departureDate, 'US').startOf('day');
  const today = getRegionDateTime('US').startOf('day');
  const daysUntilDeparture = getDurationOfDatesInDays(today, departure, 'US');
  const maxCostPerTraveler =
    daysUntilDeparture < noticeDays + 1 ? shortNoticeCostPerTraveller : longNoticeCostPerTraveller;
  const totalMaxCost = maxCostPerTraveler * numberOfTravelers;
  if (tripCost > totalMaxCost) {
    return currency(maxCostPerTraveler, { symbol: '$', precision: 0 }).format();
  }
  return undefined;
}
