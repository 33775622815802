import currency from 'currency.js';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  DEFAULT_MAX_TRIP_COST_PER_PERSON,
  DEFAULT_MED_TRIP_COST_PER_PERSON,
  DEFAULT_TRIP_COST_BY_DEPARTURE_DAYS,
} from '@packages/constants';
import { ConfigState, useConfigStore } from '@packages/stores';
import { TripCostForm } from '@packages/types';
import { getMaxAllowedTripCost } from '@packages/utils';

import { Text, YesNoRadioButton } from '@components';
import { Notification } from '@elements/notification';
import { i18n } from '@i18n';
import { getTripSelector, useTripStore } from '@store';

export function OptInPreTripCancellation({
  isModal,
  hasAgreed,
  setHasAgreed,
}: {
  isModal?: boolean;
  hasAgreed: boolean | undefined;
  setHasAgreed: (value: boolean | undefined) => void;
}) {
  const trip = useTripStore(getTripSelector);
  const selectedSecondaryTravellerCount =
    trip?.secondaryTravellers.filter(t => t.isSelected).length ?? 0;

  const {
    watch,
    formState: { errors },
  } = useFormContext<TripCostForm>();
  const tripCost = watch('totalTripCost');
  const shortNoticeCostPerTraveller = useConfigStore(shortNoticeCostPerTravellerSelector);
  const longNoticeCostPerTraveller = useConfigStore(longNoticeCostPerTravellerSelector);
  const noticeDays = useConfigStore(noticeDaysSelector);
  const maxAllowedTripCost = getMaxAllowedTripCost({
    shortNoticeCostPerTraveller,
    longNoticeCostPerTraveller,
    departureDate: trip?.startDate ?? '',
    numberOfTravelers: selectedSecondaryTravellerCount + 1,
    tripCost: currency(tripCost).value,
    noticeDays,
  });
  const hasTripCostWarning = errors?.totalTripCost?.message;
  const shouldDisplayPreTripCancellationWarning =
    !!maxAllowedTripCost && !!tripCost && !hasTripCostWarning;
  const hasExceededTripCostLimit = errors?.totalTripCost?.type === 'max';
  const isYesDisabled = !!maxAllowedTripCost || !tripCost || hasExceededTripCostLimit;
  const isNoDisabled = !tripCost || hasExceededTripCostLimit;
  const isPreTripCancellationAdded = useTripStore(
    state => state.trip?.boosts?.find(it => it?.code === 'USTC')?.isAdded,
  );

  useEffect(() => {
    if (!tripCost || hasExceededTripCostLimit) {
      setHasAgreed(undefined);
      return;
    }

    setHasAgreed(maxAllowedTripCost ? false : undefined);
  }, [hasExceededTripCostLimit, maxAllowedTripCost, setHasAgreed, tripCost]);

  useEffect(() => {
    if (isModal || isPreTripCancellationAdded === undefined) {
      return;
    }
    setHasAgreed(isPreTripCancellationAdded);
  }, [isModal, isPreTripCancellationAdded, setHasAgreed]);

  return !isModal ? (
    <>
      <Text variant="h4-24/sb">{i18n.t('tripCost.preTripCancellation.title')}</Text>
      <Text variant="body-16/r">{i18n.t('tripCost.preTripCancellation.description')}</Text>
      <YesNoRadioButton
        isYesDisabled={isYesDisabled}
        isNoDisabled={isNoDisabled}
        name="pre-trip"
        isFullWidth
        hasAgreed={hasAgreed}
        onChange={e => setHasAgreed(e.target.value === 'Yes')}
      />
      {shouldDisplayPreTripCancellationWarning ? (
        <Notification
          variant="warning"
          header={i18n.t('tripCost.tripCostExceedsWarning.title', {
            totalMaxCost: maxAllowedTripCost,
          })}
          body={i18n.t('tripCost.preTripCancellation.warningNotification.description')}
        />
      ) : null}
      <hr className="border-cabo-200 mb-6" />
    </>
  ) : null;
}

const longNoticeCostPerTravellerSelector = (state: ConfigState) =>
  state?.regionSpecificConfig?.RULES.TRIP_COST_MAX_PER_PERSON ?? DEFAULT_MAX_TRIP_COST_PER_PERSON;

const shortNoticeCostPerTravellerSelector = (state: ConfigState) =>
  state?.regionSpecificConfig?.RULES.TRIP_COST_MED_PER_PERSON ?? DEFAULT_MED_TRIP_COST_PER_PERSON;

const noticeDaysSelector = (state: ConfigState) =>
  state?.regionSpecificConfig?.RULES.TRIP_COST_BY_DEPARTURE_DAYS ??
  DEFAULT_TRIP_COST_BY_DEPARTURE_DAYS;
