import { useEffect } from 'react';

import { useConfigStore } from '@packages/stores';
import { createSortKeyByUUID } from '@packages/utils';

import { QuoteFlowContainer, TripDetails } from '@components';
import { useCruiseBoostPopUp } from '@hooks';
import { setTag } from '@sentry/react';
import '@store/trip/trip.subscriptions';
import { sendAnalyticsMyQuotePageView } from '@utils';

import { AdditionalInformation } from './additionalInformation/additionalInformation.component';
import { Benefits } from './benefits/benefits.component';
import { DailyCoverSection } from './dailyCover/dailyCoverSection.component';
import { ExtraCoverSection } from './extraCover/extraCoverSection.component';

export const MyQuote = () => {
  const QUOTE_IMAGE_TAG = useConfigStore(
    state => state.regionSpecificConfig?.MARKETING_IMAGE_TAGS?.quote,
  );

  useCruiseBoostPopUp();
  useEffect(() => {
    setTag('section', 'boostSelection');
    sendAnalyticsMyQuotePageView();
  }, []);

  return (
    <>
      {QUOTE_IMAGE_TAG && (
        <img
          src={QUOTE_IMAGE_TAG?.replace('[OrderID]', createSortKeyByUUID())}
          width="1"
          height="1"
          alt=""
          className="absolute -top-96 -left-96"
        />
      )}
      <QuoteFlowContainer>
        <Benefits />
        <ExtraCoverSection />
        <DailyCoverSection />
        <TripDetails className="relative pt-4 mt-6 border-t lg:hidden" />
        <AdditionalInformation />
      </QuoteFlowContainer>
    </>
  );
};
