import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { getRegionSelector, useRegionStore } from '@packages/stores';
import {
  getBoostModalType,
  isBoostAdded,
  isBoostCruiseCover,
  isBoostEligible,
  isBoostExtraCancellation,
  isBoostRequired,
  isSingleDestinationTrip,
} from '@packages/utils';

import { ExtraCancellationDropdown } from '@components/boostListItem/extraCancellationDropdown.component';
import { BoostPrice } from '@components/boostPrice';
import { Button } from '@elements/button';
import { useBoostSelectionModal } from '@hooks';
import { i18n } from '@i18n';
import { getSelectedBoostById, useTripStore } from '@store';
import { sendAnalyticsEvent, testProps } from '@utils';

import { getBoostPrimaryButtonAttributes } from './boostHeader.utils';

export const BoostHeaderActions = () => {
  const params = useParams();
  const boostId = params?.id ?? '';
  const selectedBoost = useTripStore(getSelectedBoostById(boostId));
  const isBoostRequiredCruiseCover =
    isBoostCruiseCover(selectedBoost) && isBoostRequired(selectedBoost);
  const isSingleDestination = useTripStore(state => isSingleDestinationTrip(state.trip));

  const region = useRegionStore(getRegionSelector);

  const isPreTripCancellationEligible = isBoostEligible(selectedBoost, region);

  const isBoostAddedToTrip = isBoostAdded(selectedBoost);
  const isExtraCancellation = isBoostExtraCancellation(selectedBoost);

  const shouldDisplayPrimaryButton =
    !isBoostAddedToTrip || !isBoostRequiredCruiseCover || !isSingleDestination;

  const isMandatoryBoostAdded = !!selectedBoost?.isMandatory && isBoostAddedToTrip;
  const boostModalType = getBoostModalType(selectedBoost);

  const isPayPerDayAndAdded = boostModalType === 'payPerDayCalendar' && isBoostAddedToTrip;

  // Primary and Secondary buttons
  const isDisplayingPrimaryButtonRemoveIcon =
    boostModalType && boostModalType !== 'addSpecifiedItems' ? false : isBoostAddedToTrip;

  const boostHeaderIcon = isMandatoryBoostAdded
    ? 'Mandatory'
    : isDisplayingPrimaryButtonRemoveIcon
      ? 'close'
      : undefined;
  const [primaryButtonText, primaryButtonVariant] = getBoostPrimaryButtonAttributes(
    selectedBoost,
    isPreTripCancellationEligible,
  );

  const [isPrimaryButtonLoading, setIsPrimaryButtonLoading] = useState(false);
  const [isSecondaryButtonLoading, setIsSecondaryButtonLoading] = useState(false);

  const isSecondaryButtonDisplayed =
    isPayPerDayAndAdded && !isMandatoryBoostAdded && !isBoostRequiredCruiseCover;

  const openBoostSelectionModal = useBoostSelectionModal(boostId);
  const removeBoost = useTripStore(state => state.removeBoost);
  const addBoost = useTripStore(state => state.addBoost);

  const onPrimaryButtonClick = async () => {
    if (isMandatoryBoostAdded) {
      return;
    }
    setIsPrimaryButtonLoading(true);

    /**
     * Opens boost selection modal, if the boost is not added
     */
    if (boostModalType) {
      // add specified items will have the remove all option if the boost is added
      if (boostModalType === 'addSpecifiedItems' && isBoostAddedToTrip) {
        sendAnalyticsEvent('All Specified Items Removed');
        const removeBoostResponse = await removeBoost({ boostId });
        setIsPrimaryButtonLoading(false);
        return removeBoostResponse;
      }
      setIsPrimaryButtonLoading(false);
      return openBoostSelectionModal();
    }

    /**
     * Boost is added to trip
     * So we remove
     */
    if (isBoostAddedToTrip) {
      const removeBoostResponse = await removeBoost({ boostId });
      setIsPrimaryButtonLoading(false);
      return removeBoostResponse;
    }

    /**
     * All other scenarios will lead the action to add the boost
     */
    const addBoostResponse = await addBoost({ boostId, updatePayload: {} });
    setIsPrimaryButtonLoading(false);
    return addBoostResponse;
  };

  const renderPrimaryButton =
    shouldDisplayPrimaryButton && !isExtraCancellation ? (
      <Button
        variant={primaryButtonVariant}
        iconLeft={boostHeaderIcon}
        onClick={onPrimaryButtonClick}
        className={twMerge(
          isMandatoryBoostAdded && isPreTripCancellationEligible && 'pointer-events-none',
        )}
        disabled={!isPreTripCancellationEligible}
        title={primaryButtonText}
        isLoading={isPrimaryButtonLoading}
        {...testProps('boost-primary-button')}
      />
    ) : null;

  const renderSecondaryButton = isSecondaryButtonDisplayed ? (
    <Button
      variant="secondary"
      iconLeft="close"
      onClick={async () => {
        setIsSecondaryButtonLoading(true);
        await removeBoost({ boostId });
        setIsSecondaryButtonLoading(false);
      }}
      title={i18n.t('boostDetails.boostHeader.secondaryButton.remove')}
      isLoading={isSecondaryButtonLoading}
      {...testProps('boost-secondary-button')}
    />
  ) : null;

  return (
    <div className="flex flex-wrap items-center gap-4">
      {selectedBoost && <BoostPrice boost={selectedBoost} type="boostDetails" />}
      {renderPrimaryButton}
      {renderSecondaryButton}
      <ExtraCancellationDropdown boostId={boostId} />
    </div>
  );
};
