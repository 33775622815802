import { TripCostEditor } from '@components';
import { ROUTE_PATHS, router } from '@routes/router';
import { useTripStore } from '@store';
import { sendAnalyticsEvent } from '@utils';

export const TripCost = () => {
  const syncTrip = useTripStore(state => state.syncTrip);
  const handleNext = async () => {
    sendAnalyticsEvent('Quote Submitted');

    const newTrip = await syncTrip();
    if (!newTrip) {
      return;
    }
    router.navigate(ROUTE_PATHS().myQuote);
  };
  return (
    <main>
      <TripCostEditor onNext={handleNext} onBack={() => router.navigate(-1)} />
    </main>
  );
};
