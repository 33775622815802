import { FC, useCallback, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { Assets } from '@assets';
import { Referral, Text } from '@components';
import { Button } from '@elements/button';
import { i18n } from '@i18n';
import { closeModalSelector, useGlobalStore, useGuestStore, useModalStore } from '@store';
import { growsurfApi, sendAnalyticsEvent, testProps } from '@utils';

export interface ReferralModalProps {
  children?: React.ReactNode;
}

export const ReferralModal: FC<ReferralModalProps> = () => {
  const guest = useGuestStore(state => state.guest);
  const [isOpen, setIsOpen] = useState(false);
  const setIsReferralModalClosed = useGlobalStore(state => state.setIsReferralModalClosed);
  const closeModal = useModalStore(closeModalSelector);
  const [isGrowsurfInitialized, setIsGrowsurfInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onGrowsurfReady = useCallback(() => {
    setIsGrowsurfInitialized(true);
  }, []);

  useEffect(() => {
    // Check to see if GrowSurf is available
    if (!window.growsurf) {
      // When refreshing the page, Listen and wait for the Growsurf Universal Code to initialize
      window.addEventListener('grsfReady', onGrowsurfReady);
    } else {
      //growsurf is already available
      setIsGrowsurfInitialized(true);
    }
    return () => window.removeEventListener('grsfReady', onGrowsurfReady);
  }, [onGrowsurfReady]);

  const handleButtonClick = async () => {
    setIsLoading(true);

    if (guest?.email) {
      await growsurfApi.addParticipant({ email: guest.email });
    }

    setIsLoading(false);
    sendAnalyticsEvent('Referral Click', {
      firstName: guest?.firstName ?? '',
      lastName: guest?.lastName ?? '',
      email: guest?.email ?? '',
    });
    setIsOpen(true);
  };

  const handleClose = () => {
    sendAnalyticsEvent(isOpen ? 'Referral url modal close' : 'Referral modal close');
    closeModal();
    setIsReferralModalClosed(true);
  };

  return (
    <div>
      <div className="absolute top-0 right-0 pt-4 pr-4">
        <Button
          type="button"
          onClick={handleClose}
          variant="tertiary"
          icon="cancel_filled"
          {...testProps('referral-modal-button-close')}
        />
      </div>
      <div className="flex flex-col items-center justify-center gap-4 px-4 pb-2 bg-mono-100 rounded-2xl">
        <Assets.Gift />
        <Text
          className="mt-2 text-center"
          variant="h2-36/sb"
          {...testProps('referral-modal-title')}>
          {i18n.t('checkout.confirmation.referral.modal.title')}
        </Text>
        <Text
          className="mt-2 text-center"
          variant="subTitle-20/r"
          {...testProps('referral-modal-description')}>
          {i18n.t('checkout.confirmation.referral.modal.description')}
        </Text>
        <Referral firstName={guest?.firstName} lastName={guest?.lastName} isModalOpen={isOpen} />
        <div className={twMerge('w-full', isOpen ? 'hidden' : 'block')}>
          <Button
            isLoading={!isGrowsurfInitialized || isLoading}
            className="w-full"
            variant="primary"
            onClick={handleButtonClick}
            title={i18n.t('checkout.confirmation.referral.modal.button')}
            {...testProps('referral-modal-button-primary')}
          />
        </div>
      </div>
    </div>
  );
};
