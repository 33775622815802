import { colors } from '@packages/design';

import { Assets } from '@assets';
import { Text } from '@components';
import { i18n } from '@i18n';

export type MoreInfoModalBodyProps = {
  modalType?: string;
};

export function MoreInfoModalBody({ modalType }: MoreInfoModalBodyProps) {
  const residentContentList = i18n.t('travellers.residentStatus.modal.content', {
    returnObjects: true,
  });
  if (!modalType) {
    return (
      <div className="text-left">
        <Text className="py-2">{i18n.t('travellers.residentStatus.modal.subtitle')}</Text>
        <ul>
          {residentContentList?.map((item: string, index: number) => {
            return (
              <li key={index} className="flex flex-row justify-center py-2">
                <Assets.Covered fill={colors.green[500]} className="pt-1" />
                <Text className="px-2 flex-1">{item}</Text>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  return <Text>{i18n.t('travellers.dependent.modal.content')}</Text>;
}
