import currency from 'currency.js';
import { useCallback, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import {
  EXTRA_CANCELLATION_DEFAULT_VALUE,
  EXTRA_CANCELLATION_UNLIMITED_VALUE,
} from '@packages/constants';
import { SelectOption } from '@packages/types';
import {
  getBoostExtraCoverValues,
  getBoostSelectedExtraCover,
  isBoostExtraCancellation,
} from '@packages/utils';

import { Button, ButtonProps } from '@elements/button';
import { Dropdown } from '@elements/dropdown';
import { TrayOptionProps } from '@elements/tray/tray.component';
import { getSelectedBoostById, useTripStore } from '@store';

type ExtraCancellationDropdownProps = {
  boostId: string;
};

const formatExtraCoverValue = (value: number) =>
  value === EXTRA_CANCELLATION_UNLIMITED_VALUE
    ? 'Unlimited'
    : currency(value + EXTRA_CANCELLATION_DEFAULT_VALUE, {
        symbol: '$',
        precision: 0,
        separator: ',',
      }).format();

const DEFAULT_OPTION: SelectOption = {
  id: '0',
  label: currency(EXTRA_CANCELLATION_DEFAULT_VALUE, {
    symbol: '$',
    precision: 0,
    separator: ',',
  }).format(),
  value: 0,
};

export const ExtraCancellationDropdown = ({ boostId }: ExtraCancellationDropdownProps) => {
  const boost = useTripStore(getSelectedBoostById(boostId));
  const boostIdValue = boost?.boostId;
  const isAdded = boost?.toUpdate?.isAdded ?? boost?.isAdded;
  const isExtraCancellation = isBoostExtraCancellation(boost);
  const selectedExtraCover = getBoostSelectedExtraCover(boost);
  const extraCoverValues = getBoostExtraCoverValues(boost);

  const addBoost = useTripStore(state => state.addBoost);
  const removeBoost = useTripStore(state => state.removeBoost);
  const [isTrayOpen, setIsTrayOpen] = useState(false);
  const isSmallScreen = useMediaQuery({ maxWidth: 640 });

  const dropdownItems = useMemo(() => {
    if (!extraCoverValues) return null;
    return [
      DEFAULT_OPTION,
      ...extraCoverValues.map(item => ({
        id: item.toString(),
        label: formatExtraCoverValue(item),
        value: item,
      })),
    ];
  }, [extraCoverValues]);

  const value = useMemo(
    () => (isAdded ? (selectedExtraCover ?? 0) : 0),
    [isAdded, selectedExtraCover],
  );

  const addExtraCancellation = useCallback(
    async (newValue: number) => {
      if (!boostIdValue) return;
      await addBoost({
        boostId: boostIdValue,
        updatePayload: {
          boostProperty: {
            selectedExtraCover: newValue,
          },
        },
      });
    },
    [boostIdValue, addBoost],
  );

  const removeExtraCancellation = useCallback(async () => {
    if (!boostIdValue) return;
    return removeBoost({ boostId: boostIdValue });
  }, [boostIdValue, removeBoost]);

  const handleOnClick = useCallback(
    async (newValue: number) => {
      if (!boostIdValue) return;
      return newValue === 0 ? removeExtraCancellation() : addExtraCancellation(newValue);
    },
    [boostIdValue, removeExtraCancellation, addExtraCancellation],
  );

  const trayOptions = useMemo(
    () =>
      dropdownItems?.map(item => ({
        title: item.label,
        onClick: () => handleOnClick(item.value as number),
        value: item.value,
        isSelected: item.value === value,
      })) ?? [],
    [dropdownItems, handleOnClick, value],
  ) as TrayOptionProps[];

  if (!isExtraCancellation || !dropdownItems) return null;

  return (
    <div className="max-w-[9rem]">
      <Dropdown
        items={dropdownItems}
        initialValue={dropdownItems.find(item => item.value === value)}
        inputProps={{
          readOnly: isSmallScreen,
          placeholder: '',
          onClick: () => isSmallScreen && setIsTrayOpen(!isTrayOpen),
          rightIcon: (props: ButtonProps) => (
            <Dropdown.Button
              as={Button}
              onClick={() => isSmallScreen && setIsTrayOpen(!isTrayOpen)}
              {...props}
            />
          ),
        }}
        trayProps={{
          options: trayOptions,
          isTrayOpen,
          onClose: () => setIsTrayOpen(false),
          className: 'sm:hidden',
        }}
        onSelect={val => handleOnClick(val as number)}
      />
    </div>
  );
};
