import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { regionDateUtils } from '@packages/stores';
import { GuestDetailsForm } from '@packages/types';
import { getEmcBoost, shouldShowBlankDob } from '@packages/utils';

import { YesNoRadioButton } from '@components';
import { Button } from '@elements/button';
import { Icon } from '@elements/icon';
import { TextWithMd } from '@elements/text/textWithMd';
import { useEmcTraveller } from '@hooks';
import { useCheckoutFlow } from '@hooks';
import { i18n } from '@i18n';
import {
  getTrip,
  getTripSelector,
  saveScreeningResultAction as saveScreeningResult,
  useBoostDetailsStore,
  useEmcStore,
  useFeatureFlagsStore,
  useTripStore,
} from '@store';
import { hasTravellerErrors, sendAnalyticsEvent, testProps, validateEmcTravellers } from '@utils';

import { EmcErrorText } from './emcErrorText.component';

export interface EmcCheckRadioGroupProps {
  firstName?: string;
  lastName?: string;
  sortKey?: string;
  isEmcProceedError?: boolean;
  dob?: string;
}

export const EmcCheckRadioGroup = ({
  firstName,
  lastName,
  sortKey,
  dob,
}: EmcCheckRadioGroupProps) => {
  const trip = useTripStore(getTripSelector);
  const setEmcDeclaring = useBoostDetailsStore(state => state.setEmcDeclaring);
  const emcBoost = getEmcBoost(trip);
  const setOpenEmcSection = useEmcStore(state => state.setOpenEmcSection);
  const { getEmcTravellerById } = useEmcTraveller();
  const hasTravellerInEmcBoostWithSameSortKey = emcBoost?.travellers?.some(
    t => t?.sortKey === sortKey,
  );

  const age = regionDateUtils().getAge(dob ?? '');

  const setErrorTravellers = useEmcStore(state => state.setErrorTravellers);
  const setShowEmcErrorsOnContinue = useEmcStore(state => state.setShowEmcErrorsOnContinue);

  const emcTraveller = getEmcTravellerById(sortKey);

  const { watch, handleSubmit } = useFormContext<GuestDetailsForm>();
  const isEmcEnabled = useFeatureFlagsStore(state => state?.featureFlags?.['emc']?.isEnabled);

  // Hides the Start Assessment button
  const [hasAgreed, setHasAgreed] = useState(emcTraveller?.isSelected);
  const { shouldShowPriceModal } = useCheckoutFlow();

  const handleRadioButtonChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!firstName || !lastName || !dob) {
      const errors = validateEmcTravellers(emcBoost, {
        firstName,
        lastName,
        dob,
      } as GuestDetailsForm);
      if (hasTravellerErrors(errors)) {
        e.currentTarget.checked = false;
        setErrorTravellers(errors);
        setShowEmcErrorsOnContinue(true);
        return;
      }
    }

    setHasAgreed(e.target.value === 'Yes');
    setShowEmcErrorsOnContinue(false);

    await saveScreeningResult({
      isAdded: false,
      isSelected: e.target.value === 'Yes',
      firstName,
      lastName,
      travellerId: sortKey,
      isCompleted: false,
    });
    const response = await getTrip();
    if (response) {
      const emcBoost = getEmcBoost(response);
      const errors = validateEmcTravellers(emcBoost);
      setErrorTravellers(errors);
    }

    sendAnalyticsEvent('EMC Button Clicked', {
      Value: e.target.value as 'Yes' | 'No',
      TravellerID: sortKey ?? '',
    });
  };

  /*
   BR: Check if the DOB of any primary or secondary traveler has changed.
   If so, display the price change modal.
   For instance, if the DOB of the primary traveler changes and the Start Assessment
   button is clicked for a secondary traveler, trigger the price change modal.
   Otherwise, continue to the EMC flow.
*/

  const handleStartAssessment = useCallback(() => {
    sendAnalyticsEvent('Start assessment button clicked', {
      TravellerID: sortKey ?? '',
    });

    const checkoutResult = shouldShowPriceModal(watch());
    if (checkoutResult === 'priceChangeModal') {
      return;
    }
    setEmcDeclaring({
      boostId: emcBoost?.boostId ?? '',
      firstName: firstName ?? '',
      lastName: lastName ?? '',
      travellerId: sortKey ?? '',
      age,
    });
    setOpenEmcSection('information');
  }, [
    age,
    emcBoost?.boostId,
    firstName,
    lastName,
    setEmcDeclaring,
    setOpenEmcSection,
    shouldShowPriceModal,
    sortKey,
    watch,
  ]);

  if (!isEmcEnabled || !emcBoost || !hasTravellerInEmcBoostWithSameSortKey) {
    return null;
  }

  return (
    <div className="my-4">
      <div className="flex max-w-[90%] sm:max-w-auto mb-4">
        <TextWithMd
          onLinkClick={() => {
            setOpenEmcSection('information', true);
          }}>
          Do you need cover for an Existing [Medical Condition]()?
        </TextWithMd>
        <Icon
          size="md"
          className="text-link-default cursor-pointer ml-s4"
          name="info"
          onClick={() => setOpenEmcSection('information', true)}
        />
      </div>
      <EmcErrorText sortKey={sortKey} />
      <YesNoRadioButton
        onChange={handleRadioButtonChange}
        id={`emc-for-${firstName}-${lastName}-${sortKey}`}
        name={`emc-for-${firstName}-${lastName}-${sortKey}`}
        hasAgreed={hasAgreed}
      />
      {hasAgreed ? (
        <Button
          variant="feature"
          className="mt-4"
          disabled={
            shouldShowBlankDob(trip.primaryTraveller)
              ? false
              : !firstName || !lastName || !hasAgreed
          }
          onClick={
            shouldShowBlankDob(trip.primaryTraveller)
              ? handleSubmit(handleStartAssessment)
              : handleStartAssessment
          }
          title={i18n.t('emcShared.global.assessment.button')}
          {...testProps('btn-emc-assessment')}
        />
      ) : null}
    </div>
  );
};
