import { FC } from 'react';

import { getRegionSelector, useRegionStore } from '@packages/stores';
import { Boost, BoostPriceType } from '@packages/types';
import { isBoostEligible, isBoostExtraCancellation } from '@packages/utils';

import { SkeletonDiv } from '@elements/skeleton';

import { Price } from './price.component';
import { Unit } from './unit.component';

interface Props {
  boost: Boost;
  type: BoostPriceType;
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
}

export const BoostPrice: FC<Props> = ({ boost, className, type }) => {
  const region = useRegionStore(getRegionSelector);
  const isBoostToggling = boost?.toggleStatus;
  const isExtraCancellation = isBoostExtraCancellation(boost);

  // if the pre trip cancellation is not eligible, we don't want to show the price
  const isPreTripCancellationEligible = isBoostEligible(boost, region);
  if (!isPreTripCancellationEligible) return null;

  const priceClassName = type === 'boostCard' ? 'pr-1 -mb-1' : undefined;
  const isPriceLoading = isBoostToggling || !boost.price;

  if (isPriceLoading && isExtraCancellation) {
    return (
      <div className={className || 'flex w-full md:w-auto animate-pulse'}>
        <SkeletonDiv className="w-8 h-6" />
      </div>
    );
  }

  return (
    <div className={className || 'flex w-full md:w-auto'}>
      <Price boost={boost} type={type} className={priceClassName} />
      <Unit boost={boost} type={type} />
    </div>
  );
};
