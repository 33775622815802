import { Radio } from '@elements/radio';
import { testProps } from '@utils';

export type YesNoRadioButtonProps = {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  hasAgreed?: boolean;
  name: string;
  isFullWidth?: boolean;
  isYesDisabled?: boolean;
  isNoDisabled?: boolean;
};
export const YesNoRadioButton = ({
  id,
  hasAgreed,
  onChange,
  name,
  isFullWidth,
  isYesDisabled = false,
  isNoDisabled = false,
}: YesNoRadioButtonProps) => {
  const hasSelectedNo = hasAgreed === false;
  const hasSelectedYes = hasAgreed === true;
  return (
    <div className="flex">
      <Radio
        disabled={isYesDisabled}
        isFullWidth={isFullWidth}
        title="Yes"
        checked={hasSelectedYes}
        id={`${id ?? ''}-yes`}
        onChange={onChange}
        name={name}
        {...testProps('radio-yes')}
      />
      <Radio
        disabled={isNoDisabled}
        isFullWidth={isFullWidth}
        name={name}
        title="No"
        checked={hasSelectedNo}
        classnames="ml-4"
        id={`${id ?? ''}-no`}
        onChange={onChange}
        {...testProps('radio-no')}
      />
    </div>
  );
};
