import { get, post, put } from 'aws-amplify/api';
import { fetchUserAttributes, signIn, signOut } from 'aws-amplify/auth';

import type { GuestDetails } from '@packages/types';
import { currentCredentials, getCognitoJwtToken, throwError } from '@packages/utils';

export const amplifySignIn = async (arg: { email: string; signInPassword: string }) => {
  const { isSignedIn } = await signIn({
    username: arg.email.toLowerCase(),
    password: arg.signInPassword,
  });

  const attributes = await fetchUserAttributes();

  return {
    isSignedIn,
    email: attributes.email ?? '',
    sub: attributes.sub ?? '',
  };
};

export const freelySignIn = async () => {
  const accessToken = await getCognitoJwtToken();
  const apiName = 'User';
  const path = '/sign-in';
  const options = {
    headers: {
      Authorization: accessToken,
    },
  };
  const result = await post({ apiName, path, options }).response;
  return (await result.body.json()) as any;
};

export const amplifySignOut = async () => {
  await signOut();
};

export const freelySignOut = async () => {
  const apiName = 'User';
  const path = '/sign-out';
  const result = await post({ apiName, path }).response;
  return (await result.body.json()) as any;
};

export const fetchUserById = async () => {
  const apiName = 'User';
  const path = '/profile';

  try {
    //BE NEEDS TO UPDATE SCHEMA
    const result = await get({ apiName, path }).response;
    return (await result.body.json()) as any;
  } catch (error: any) {
    return throwError({ error, message: 'Fetching user by Id failed. Please try again.' });
  }
};

export const updateGuestUser = async (arg: { guest: GuestDetails }) => {
  const identity = await currentCredentials();
  const apiName = 'User';
  const path = '/guest';

  const guest = Object.assign({}, arg.guest) as any;

  if (guest?.cardDetails) {
    delete guest?.cardDetails;
  }
  const options = {
    identity,
    body: guest,
  };
  const result = await put({ apiName, path, options }).response;
  return (await result.body.json()) as any;
};
