import { HTMLAttributes } from 'react';
import { Sheet } from 'react-modal-sheet';
import { tv } from 'tailwind-variants';

import { SelectOption } from '@packages/types';

import { Icon } from '@elements/icon';
import { Text } from '@elements/text';

export type TrayOptionProps = {
  icon?: string;
  title: string;
  value: string;
  onClick: () => Promise<void> | void;
  isSelected?: boolean;
  onClose: () => void;
  onTrayItemSelect?: (item: SelectOption) => void;
};

const trayOptionsStyle = tv({
  base: 'flex items-center px-s12 py-s8 rounded-br-sm border-2 cursor-pointer border-transparent active:bg-surface-action-tertiary-hover hover:bg-surface-action-selected ',
  variants: {
    isSelected: {
      true: 'border-border-action-active bg-surface-action-selected hover:border-transparent',
    },
  },
});

const TrayOptions = ({
  icon,
  title,
  onClick,
  isSelected = false,
  value,
  onClose,
  onTrayItemSelect,
}: TrayOptionProps) => {
  const trayOptionClassName = trayOptionsStyle({ isSelected });
  const handleOnClick = async () => {
    onClose();
    await onTrayItemSelect?.({ label: title, value, id: value });
    await onClick();
  };

  return (
    <div onClick={handleOnClick} className={trayOptionClassName}>
      {icon ? <Icon name={icon} className="text-icon-brand-2 pr-s8" /> : null}
      <Text variant="body-16/r">{title}</Text>
    </div>
  );
};
export type TrayProps = {
  options: TrayOptionProps[];
  isTrayOpen: boolean;
  onClose: () => void;
  onTrayItemSelect?: (item: SelectOption) => void;
} & HTMLAttributes<HTMLDivElement>;

export const Tray = ({ options, isTrayOpen, onClose, className, onTrayItemSelect }: TrayProps) => {
  return (
    <Sheet detent="content-height" isOpen={isTrayOpen} onClose={onClose} className={className}>
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content className="flex flex-col gap-s4 px-s16 py-s24">
          <Sheet.Scroller draggableAt="both">
            {options.map((option, index) => (
              <TrayOptions
                key={`${option.title}-${index}`}
                {...option}
                onClose={onClose}
                onTrayItemSelect={onTrayItemSelect}
              />
            ))}
          </Sheet.Scroller>
        </Sheet.Content>
      </Sheet.Container>
      {/* As the element is a motion component you need to use onTap instead of onClick if you want to add a click handler to it */}
      <Sheet.Backdrop onTap={onClose} />
    </Sheet>
  );
};
