import { DateTime } from 'luxon';
import type { ReactNode } from 'react';
import type { RESULTS } from 'react-native-permissions';

// ---------------- ENUMS --------------------------//

export enum PAYMENT_TYPE {
  VISA = 'Visa',
  MASTER_CARD = 'Mastercard',
  AMEX = 'American Express',
  DISCOVER = 'Discover',
  APPLE_PAY = 'ApplePay',
  GOOGLE_PAY = 'GooglePay',
  CREDIT_CARD = 'CreditCard',
}

export enum THEME_TYPE {
  DARK = 'DARK',
  LIGHT = 'LIGHT',
  WHITE = 'WHITE',
}

export type AuthType =
  | 'email_sign_in'
  | 'google_sign_in'
  | 'apple_sign_in'
  | 'email_sign_up'
  | 'google_sign_up'
  | 'apple_sign_up';

export enum USER_EVENT {
  AUTHENTICATED = 'AUTHENTICATED',
  SWITCH_APP_STATE = 'SWITCH_APP_STATE',
  DEEP_LINK_CLICKED = 'DEEP_LINK_CLICKED',
}

export enum FLOW_TYPE {
  SOCIAL_ONBOARDING = 'SOCIAL_ONBOARDING',
  EMAIL_ONBOARDING = 'EMAIL_ONBOARDING',
  BASIC_COVER_ACTIVATION = 'BASIC_COVER_ACTIVATION',
}
export enum BOOST_TYPE {
  NORMAL = 'NORMAL',
  PERDAY = 'PERDAY',
  EMC = 'EMC',
}
export enum PERMISSION_STATUS_TYPE {
  'UNAVAILABLE' = 'unavailable',
  'DENIED' = 'denied',
  'BLOCKED' = 'blocked',
  'GRANTED' = 'granted',
}

export enum SOCIAL_ONBOARDING_STEPS {
  CREATE_PIN_CODE = 'CreatePinCode',
  CONSENT = 'Consent',
  ENABLE_NOTIFICATION = 'EnableNotification',
}
export enum EMAIL_ONBOARDING_STEPS {
  NAME = 'Name',
  EMAIL_PASSWORD = 'EmailPassword',
  VERIFICATION_CODE = 'VerificationCode',
  CREATE_PIN_CODE = 'CreatePinCode',
  CONSENT = 'Consent',
  ENABLE_NOTIFICATION = 'EnableNotification',
}
export enum BASIC_COVER_ACTIVATION_STEPS {
  CONFIRM_NAME = 'ConfirmName',
  DATE_OF_BIRTH = 'DateOfBirth',
  ADDRESS = 'Address',
  PRIVACY_COMMITMENT = 'PrivacyCommitment',
  ENABLE_LOCATION = 'EnableLocation',
  ENABLE_MOTION = 'EnableMotion',
}
export enum ONBOARDING_STATUS {
  CONSENT = 'Consent',
  CONSENTED = 'Consented',
  ENABLE_NOTIFICATION = 'ENABLE_NOTIFICATION',
}
export enum CoverMedicalType {
  PREMIUM = 'PREMIUM',
}

export enum PushNotificationType {
  TEXT_CHAT = 'TEXT_CHAT',
  RATING_REQUEST = 'RATING_REQUEST',
  ATTACHMENT = 'ATTACHMENT',
  CLAIMS = 'CLAIMS',
}
export enum TRIP_STATUS {
  NO_BOOST = 'NO_BOOST',
  PAID = 'PAID',
  CANCELLED = 'CANCELLED',
  UPDATING = 'UPDATING',
  WAITING_PAYMENT = 'WAITING_PAYMENT',
}

export enum DATE_TIME_DURATION {
  YEAR = 'year',
  MONTH = 'months',
  WEEK = 'weeks',
}

export enum ClaimMode {
  VIEW,
  EDIT,
}
export enum BOOST_UI_STATUS {
  ADDING = 'ADDING',
  REMOVING = 'REMOVING',
}
export enum PAID_TRIP_CHANGES_RESULT_TYPES {
  EXTRA_PAYMENT = 'EXTRA_PAYMENT',
  NO_ACTION = 'NO_ACTION',
  REFUNDED = 'REFUNDED',
}

export enum TRIP_TYPE {
  INTERNATIONAL = 'INTERNATIONAL',
  DOMESTIC = 'DOMESTIC',
}

export enum MESSAGE_MODAL_TYPE {
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  MAINTENANCE = 'MAINTENANCE',
  PAYMENT_DONE = 'PAYMENT_DONE',
}

export enum BOOST_CATEGORY {
  MY_HEALTH = 'MY_HEALTH',
  MY_ACTIVITIES = 'MY_ACTIVITIES',
  MY_STUFF = 'MY_STUFF',
  MY_EXPENSES = 'MY_EXPENSES',
  BOOST_SUMMARY = 'BOOST_SUMMARY',
  MY_EMC = 'MY_EMC',
}

export enum ADD_BOOST_LABEL {
  ADD_TO_TRIP = 'Add to Trip',
  ADD_ITEMS = 'Add Items',
  SELECT_LIMIT = 'Select Limit',
  YES = 'Yes',
}

export interface State {
  name: string;
  code: string;
}

export type RegionCode = 'AU' | 'US';

export interface RegionData {
  name: string;
  code: RegionCode;
  states: Array<State>;
}

export interface Region {
  country: RegionCode;
  state?: string | null;
}

export type Route = {
  routeName: string;
  screen: string;
  params?: any;
};

// ---------------- TYPES & INTERFACES --------------------//
export interface NavigationProgress {
  step: number;
  totalSteps: number;
}
export interface APIError {
  message: string;
}

type ResultKeys = keyof typeof RESULTS;
export type PermissionResults = (typeof RESULTS)[ResultKeys] | null;

export interface PermissionsStatus {
  locationPermission?: PermissionResults;
  motionPermission?: PermissionResults;
  notificationPermission?: PermissionResults;
}

export type BoostTagType = 'POPULAR' | 'REQUIRED';
export interface Boost {
  isAdded: boolean;
  duration: number;
  tripId: string;
  boostId: string;
  userId: string;
  cmName: string;
  benefits: Array<{ name: string; description: string }>;
  version: string;
  category: BOOST_CATEGORY;
  code: string;
  name: string;
  isMandatory: boolean;
  description: string;
  price: string;
  currency: string;
  boostProperty: Partial<BoostProperty>;
  startEndDates: Array<{
    startDate: string;
    endDate: string;
  }>;
  tags?: BoostTagType[];
  hasError?: boolean;
  toUpdate: ToUpdateBoost | null;
  toggleStatus?: BOOST_UI_STATUS | null;
  travellers?: Array<Traveller | SecondaryTraveller> | null;
  isSelected?: boolean;
  template?: BoostDetailsTemplate | null;
  imageURL?: string | null;
  isEligible?: boolean;
}

export interface ToUpdateBoost {
  isAdded: boolean;
  isSelected: boolean;
  priceChange: number;
  duration: number;
  boostProperty: BoostProperty;
  adjustedPrice: number;
  updateDate: string;
  startEndDates: Array<{
    startDate: string;
    endDate: string;
  }>;
}

export type ScreeningResult = {
  CalculationType: string;
  Language: 'en';
  Result: string;
};

export type EmcDataType = {
  firstName: string;
  lastName: string;
  isAdded?: boolean;
  isSelected?: boolean;
  price: string;
  riskScore?: number;
  screeningResult?: ScreeningResult[];
  medicalConditions: TravellerMedicalConditions[];
  assessmentNumber?: string;
  isCovered: boolean;
  screeningData?: string;
  isPrimaryTraveller?: boolean;
  requireRedeclare?: boolean;
  travellerId: string;
  isCompleted?: boolean;
  decryptedScreeningResult?: any[];
};

export interface BoostProperty {
  boostType: BOOST_TYPE;
  coverAmountValues: number[] | null;
  options: { value: number; description: string }[] | null;
  selectedExtraCover: number | null;
  anyDependentBoostIds: string[] | null;
  payPerDayRate?: number;
  specifiedItems?: SpecifiedBoostItem[] | null;
  basePrice?: number | null;
  mutuallyExclusiveBoostIds?: Array<string>;
  travellers?: Traveller[] | SecondaryTraveller[] | null;
  emcData?: EmcDataType[];
}
export interface SpecifiedBoostItem {
  itemId?: string;
  travellerId: string;
  itemName: string;
  selectedValue: number;
  price?: number;
  dob?: string;
}

export interface BoostCoverBoldText {
  type: 'bold';
  value: string;
}

export interface BoostCoverText {
  type: 'text';
  value: string;
}
export interface BoostCoverBulletPoints {
  type: 'bullet';
  value: string[];
}

export type BoostCoverTableItem = {
  title: string;
  value: string;
  group?: string | number;
  child?: { title: string; value: string };
};

export interface BoostCoverTable {
  type: 'table';
  items: BoostCoverTableItem[];
  columnNames: { title: string; value: string };
}

export type SheetTable = Omit<BoostCoverTable, 'type'>;
export interface BoostSheetTable {
  type: 'sheet-table';
  title: string;
  tables: SheetTable[];
}

export type BoostCoverItem =
  | BoostCoverBoldText
  | BoostCoverText
  | BoostCoverBulletPoints
  | BoostCoverTable
  | BoostSheetTable;

export enum BOOST_TYPES {
  SPECIFIED_ITEMS = 'LUGG',
  EXTRA_CANCELLATION = 'CANX',
  GADGET_COVER = 'GADGFREE',
}

export interface BoostDetails extends Boost {
  boostId: string;
  template: BoostDetailsTemplate;
}

export interface BoostDetailsTemplate {
  id: string;
  title: // AU
  | 'Domestic Explorer'
    | 'Overseas Explorer'
    | 'Snow Sports'
    | 'Rental Vehicle Insurance Excess'
    | 'Motorcycle Cover'
    | 'Adventure Activities'
    | 'Cruise Cover'
    | 'Extra Cancellation'
    | 'Gadget Cover'
    | 'Specified Items'
    // US
    | 'Adventure Activities.'
    | 'Snow Activities.'
    | 'Rental Car Damage.'
    | 'Luggage Upgrade.'
    | 'Pre-Trip Cancellation.'
    | 'Trip Inconvenience.'
    | 'The Essentials Plan.';
  categoryCaption?: string;
  boostMessage?: string;
  preconditionBoostIds: string[] | null;
  requiredCoverAmount: number | null;
  subTitle?: string;
  sortKey: string;
  market: RegionCode;
  description: string;
  version: string;
  templateId: string;
  coverDetails: {
    covered: {
      title: string | null;
      items: BoostCoverItem[] | null;
    };
    notCovered: {
      title: string | null;
      items: BoostCoverItem[] | null;
    };
  };
  pdsLink: {
    title: string;
    value: string;
  };
  inclusions: BoostDetailsInclusion[];
  additionalInformation: {
    heading: string;
    items: AdditionalInformationItem[];
  };
  excess: string;
}

export type EmcDeclaring = {
  boostId: string;
  travellerId: string;
  age: number;
  firstName: string;
  lastName: string;
  screeningData?: string;
  requireReDeclare?: boolean;
};

export interface AdditionalInformationItem {
  title: string;
  description: string;
}
export interface BoostDetailsInclusion {
  iconPath: string;
  subTitle?: string;
  title: string;
  description?: string;
}

export interface ChatInputForm {
  message: string;
}
export interface PushNotificationPayload {
  foreground: boolean;
  notificationId: string;
  notificationType: PushNotificationType;
  title: string;
  body: string;
}

export interface PrimaryTraveller {
  firstName: string;
  lastName: string;
  dob: string | null;
  isResident?: boolean | null;
  address?: string | null;
  email?: string | null;
  temporaryDob?: boolean;
}

export interface TravellerMedicalConditions {
  name: string;
  isCovered: boolean;
}

export interface Traveller {
  sortKey?: string;
  firstName: string;
  lastName: string;
  dob: string | null;
  isPrimary?: boolean;
  isAdult?: boolean;
  isDependant: boolean | null;
  isResident: boolean | null;
}

export interface SecondaryTraveller extends Traveller {
  idx?: number;
  isSelected: boolean;
  isDependant: boolean;
  isResident: boolean;
  isPrimary?: boolean;
  temporaryDob?: boolean;
}

export type TravellersAgeRanges = 'Child' | 'Young Adult' | 'Adult';

export type TravelSearchCategory =
  | 'adventureDestinations'
  | 'popularDestinations'
  | 'beachDestinations'
  | 'cruiseDestinations'
  | 'snowDestinations';

export interface TripDestination {
  countryId: string;
  longName: string;
  countryCode: string;
  imageLinks?: { app?: string; desktop?: string; mobile?: string; tablet?: string };
  category?: DestinationCategory;
  isTrending?: boolean;
  countryName?: string;
  isCity?: boolean;
  imageName?: string;
  countryFlagImage?: string;
  emcRiskCode?: number;
  isDisplayedInTravelSearch?: boolean;
  iso2?: string;
  travelSearchCategories?: TravelSearchCategory[];
}

export type DestinationCategory =
  | 'allDestinations'
  | 'allCruiseDestinations'
  | 'trendingDestinations'
  | 'allRegionDestinations';

export type QuoteDestinationsResponse = {
  trendingDestinations?: TripDestination[];
  allDestinations: TripDestination[];
  destinations: TripDestination[];
};

export type TOptionalFields<T, U extends keyof T> = Omit<T, U> & Partial<Pick<T, U>>;

export type ScreeningResultRequest = {
  country: RegionCode;
  boostId: string;
  primaryTravellerId: string;
  travellerId: string;
  isAdded: boolean;
  isSelected: boolean;
  result?: Array<unknown> | null;
  firstName: string | null;
  lastName: string | null;
  isCompleted?: boolean;
};

export type ScreeningResultParams = Partial<ScreeningResultRequest>;

export type ScreeningResultResponse = 'Success';

export interface Policy {
  effectiveDate?: Date;
  expireDate?: Date;
  orderDate?: Date;
  policyNumber: string | null;
  issueTime?: string;
  issuingSystem?: string;
}

export interface PromotionCode {
  appLabel: string;
  discountCode: string | null;
  productIds: string[];
  productId?: string;
  isFreeOfCharge: boolean;
  reservedUntil: number | null;
  discount: string;
  autoDiscountCodeLabel?: string;
}

export interface USCreateTripData {
  totalTripCost?: number | null;
  state: Region['state'];
  addCancellationBoost?: boolean;
}

export interface Trip {
  sortKey: string | null;
  startDate: string;
  endDate: string;
  destinations: TripDestination[];
  secondaryTravellers: SecondaryTraveller[];
  primaryTravellerId: string | null;
  primaryTraveller: PrimaryTraveller | null;
  state: TRIP_STATUS | null;
  boosts: Boost[];
  price: number;
  presignedCoiLink: string | null;
  pdsLink: string | null;
  hasAddedBoosts: boolean;
  pendingUpdates: TripPendingUpdates | null;
  isTripStarted: boolean;
  totalTripCost?: number | null;
  additionalProperties?: {
    addCancellationBoost?: boolean;
  };
  us?: USCreateTripData;
  tripType?: TRIP_TYPE;
  policyStatus?: 'ERROR' | 'NORMAL' | 'TIMEOUT';
  currentScreen?: BOOST_CATEGORY;
  //title is for showing 'PAST TRIPS' in the trip list
  title?: string;
  policy?: Policy | null;
  quoteId?: string;
  userState?: string;
  promotionCode?: PromotionCode;
  productId: string;
  isMock?: boolean;
  metaData?: {
    excessAmount?: number;
    partnerId?: string;
    quoteId?: string;
    quotePartnerName?: string;
    affiliates?: [
      {
        activationCode: string;
        activationDate: string;
      },
    ];
  };
  createTs?: number;
  updatedAt?: number;
  referralParticipantId?: string | null;
  referralCampaignId?: string | null;
  userId?: string | null;
}

export type CreateQuoteRequest = Pick<
  Trip,
  'primaryTraveller' | 'secondaryTravellers' | 'startDate' | 'endDate' | 'productId'
> & {
  destinations: TripDestination[];
  createTrip?: boolean;
  us?: USCreateTripData;
  totalTripCost?: number | null;
  promotionCode?: Pick<PromotionCode, 'discountCode'>;
  referralParticipantId?: string;
  referralCampaignId?: string;
};

export enum UPSELL_BOOST_NAME {
  LUGGAGE = 'LUGGAGE_UPSELL',
  MOTORCYCLE = 'MOTORCYCLE_UPSELL',
}

export interface TripDuration {
  startDate: string;
  endDate: string;
}

export interface TripPendingUpdates {
  totalPriceChange: number;
  adjustedPrice: number;
  endDate?: string;
  startDate?: string;
}

export interface ClaimIncidentPlace {
  country: string | null;
  city: string | null;
}
export interface ClaimBankAccountDetail {
  accountName: string | null;
  bsb: string | null;
  accountNumber: string | null;
}
export interface ClaimContactDetail {
  countryCallingCode: string | null;
  phone: string | null;
}

export type ClaimStatus = 'DRAFT' | 'PROCESSING' | 'SUBMITTED' | 'FAILED';

export type ResourceFile = {
  filePath: string;
  fileSize?: number | null;
  note?: string;
  error?: string;
};

export type ClaimEvidence = {
  title: CLAIM_EVIDENCE_TITLE;
  files: ResourceFile[];
};

export enum CLAIM_EVIDENCE_TITLE {
  PROOF_OF_PURCHASE = 'PROOF_OF_PURCHASE',
  PROOF_OF_PURCHASE_LOST_OR_STOLEN_PHONE = 'PROOF_OF_PURCHASE_LOST_OR_STOLEN_PHONE',
  PROOF_OF_PURCHASE_LOST_OR_STOLEN_ITEM = 'PROOF_OF_PURCHASE_LOST_OR_STOLEN_ITEM',
  CONFIRMATION_LUGGAGE_RETURNED = 'CONFIRMATION_LUGGAGE_RETURNED',
  DAMAGED_REPORT = 'DAMAGED_REPORT',
  AIRLINE_COMPENSATION = 'AIRLINE_COMPENSATION',
  TRANSPORT_PROVIDER_CONFIRMING_LIABILITY = 'TRANSPORT_PROVIDER_CONFIRMING_LIABILITY',
  LOSS_OR_STOLEN_PHONE = 'LOSS_OR_STOLEN_PHONE',
  LOSS_REPORT = 'LOSS_REPORT',
  PROPERTY_IRREGULARITY_REPORT = 'PROPERTY_IRREGULARITY_REPORT',
  HOSPITAL_ADMISSION_DISCHARGE = 'HOSPITAL_ADMISSION_DISCHARGE',
  MEDICAL_OR_DENTAL_REPORT = 'MEDICAL_OR_DENTAL_REPORT',
  RECEIPTS_OR_CREDIT_CARD_STATEMENTS = 'RECEIPTS_OR_CREDIT_CARD_STATEMENTS',
  COPIES_RECEIPTS_OR_CREDIT_CARD_STATEMENTS = 'COPIES_RECEIPTS_OR_CREDIT_CARD_STATEMENTS',
  AMBULANCE_REPORT = 'AMBULANCE_REPORT',
  COPY_VEHICLE_RENTAL_AGREEMENT = 'COPY_VEHICLE_RENTAL_AGREEMENT',
  COPY_POLICE_REPORT = 'COPY_POLICE_REPORT',
  COPY_REPAIR_REPORT = 'COPY_REPAIR_REPORT',
  SUPPORTING_DOCUMENTATION = 'SUPPORTING_DOCUMENTATION',
  TRIP_SUMMARY = 'TRIP_SUMMARY',
  RECEIPTS_OR_INVOICES = 'RECEIPTS_OR_INVOICES',
}

export interface EmergencyContact {
  name?: string;
  contactNumber?: string;
}

export interface AuthUser extends UserProfile {
  addressCoords: LocationData;
  addressDetail: Array<AddressComponent>;
  addressTz: string;
  appPlatform: string;
  classVersion: string;
  createTs: string;
  emergencyFlag: boolean;
  initialUserName: string;
  isOnline: boolean;
  isUserMeta: number;
  lastMutationEventId: string;
  lastTypingPrivateTs: string;
  lastTypingPublicTs: string;
  marketAttributes: {
    market: RegionCode;
    state?: string | null;
  };
  medicalFlag: boolean;
  phoneNumber: string;
  priorityFlag: boolean;
  sentianceId: string;
  sortKey: string;
  tokenId: string;
  updatedAt: number;
  updatedDate: string;
  version: number;
  email: string;
}

export interface GuestDetails {
  firstName?: string;
  lastName?: string;
  email?: string;
  marketAttributes?: {
    market: RegionCode;
    state?: string | null;
  };
  dob?: string;
  secondaryTravellers?: SecondaryTraveller[];
  address?: string;
  addressData?: GooglePlaceAddressData;
  userId?: string;
  addressDetail?: AddressComponent[];
  primaryTravellerId?: string;
  deviceId?: string | null;
}

export type CardDetails = {
  cardNumber: string;
  cardType?: PAYMENT_TYPE;
  expiryDate: string;
  nameOnCard: string;
};

export interface PaymentForm {
  cardDetails: CardDetails;
}

export interface EmailQuoteDetails {
  firstName: string;
  lastName: string;
  email: string;
}
export interface UserProfile {
  firstName: string;
  lastName: string;
  userId: string;
  phoneNumber?: string | null;
  photo: string | null;
  avatarUrl: string | null;
  dob: string;
  emergencyContact: EmergencyContact;
  secondaryTravellers: SecondaryTraveller[];
  address: string;
  addressData: GooglePlaceAddressData;
  state?: string | null;
  market?: RegionCode | null;
  isMessageRead: boolean;
  affiliateRecords?: [
    {
      activationCode: string;
      activationDate: string;
    },
  ];
}

export interface UserProfileToUpdate {
  firstName?: string | null;
  lastName?: string | null;
  dob?: string | null;
  emergencyContact?: EmergencyContact | null;
  addressData?: GooglePlaceAddressData | null;
  marketAttributes?: {
    market: RegionCode | undefined | null;
    state: string | null | undefined;
  };
  checkForUnpaidTrips?: boolean;
  phoneNumber?: string | null;
  affiliateRecords?: [
    {
      activationCode: string;
      activationDate: string;
    },
  ];
}

export interface MarkedDates {
  [date: string]: any;
}
export interface GooglePlaceData {
  description: string;
}

export interface Prediction {
  fullText: string;
  primaryText: string;
  secondaryText: string;
  placeID: string;
  types: string[];
  distance: number;
}
export interface LocationData {
  lat: string;
  lng: string;
}

export interface AddressComponent {
  long_name: string;
  short_name: string;
  types: Array<string>;
}

export interface PlaceDetail {
  addressComponents: Array<AddressComponent>;
  location: LocationData;
}

export interface GooglePlaceDetail {
  address_components: Array<AddressComponent>;
  location?: LocationData;
}
export interface GooglePlaceAddressData {
  data: GooglePlaceData;
  detail?: GooglePlaceDetail | null;
}
export interface SoftDenyModalPageData {
  heading: string;
  subheading: string;
  listItems?: Array<string>;
  primaryButtonTitle: string;
  secondaryButtonTitle?: string;
}

export interface ToastProps {
  isVisible: boolean;
  message?: string;
  actionMessage?: string;
  messageColor?: string;
  actionMessageColor?: string;
  backgroundColor?: string;
}

export interface PaidTripChangesResult {
  action: PAID_TRIP_CHANGES_RESULT_TYPES;
  message: string;
  amount: number;
  refundAmount: number;
}

export interface SpecifiedItemErrorInfo {
  message: string | null;
  visible: boolean;
}

interface IrregularTimeSchedule {
  start: string | number;
  end: string | number;
}

interface RegularTimeSchedule {
  baseTimestamp: number; // milliseconds
  interval: number; // milliseconds
  duration: number; // milliseconds
}
export interface MaintenanceWindow {
  regular?: RegularTimeSchedule;
  irregular: IrregularTimeSchedule[];
}

export type CalendarData = {
  durationInDays: number;
};

export enum GeneralEventType {
  COI_UPDATED = 'COI_UPDATED',
  POLICY_ISSUED = 'POLICY_ISSUED',
  INDIVIDUAL_EVENT = 'INDIVIDUAL_EVENT',
}

export interface GeneralEvent {
  id: string;
  userId: string;
  eventType: GeneralEventType;
}

export interface COIUpdatedEvent extends GeneralEvent {
  eventType: GeneralEventType.COI_UPDATED;
  tripId: string;
  presignedCoiLink: string;
}

export interface PolicyIssuedEvent extends GeneralEvent {
  eventType: GeneralEventType.POLICY_ISSUED;
  tripId: string;
  paymentStatus: string;
  policyIssuance:
    | 'SUCCESS'
    | 'FAILURE'
    | 'ISSUE_SUCCEEDED'
    | 'UPDATE_SUCCEEDED'
    | 'TIMEOUT'
    | 'INVALID_CODE';
  policyNumber: string | null;
}

export interface IndivudualEvent extends GeneralEvent {
  eventType: GeneralEventType.INDIVIDUAL_EVENT;
  message: string;
}

export type SCEENING_RESULT_STATUS = 'SCREENING_RESULT_PASSED' | 'SCREENING_RESULT_FAILED';

export type TestProps = {
  accessible?: boolean;
  accessibilityLabel?: string;
  testID?: string;
};

export enum MessageType {
  TEXT_MESSAGE = 'TextMessage',
  ALERT_MESSAGE = 'AlertMessageObject',
  ATTACHMENT = 'FileObjectMessage',
  RATING = 'RatingMessage',
  OUT_OF_OFFICE = 'OutOfOffice',
  SYSTEM_MESSAGE = 'SystemMessage',
  RISK_MESSAGE = 'RiskAlertMessageObject',
}

export enum ChatActions {
  startTypingMessage = 0,
  fetchMessages,
  appendMessage,
  updateMessage,
  addIntroMessage,
  updateTicketId,
  showOutOfOffice,
  showRating,
  hideRating,
  clearChatFeed,
  updateTypingIndicator,
}

export enum UploadState {
  Uploading,
  Complete,
  Failed,
}

export interface Action {
  actionType: ChatActions;
  payload: any;
}

export interface OutOfOfficeHours {
  hour: number;
  minute: number;
}

export interface FileObject {
  id: string;
  messageType: string;
  fileName: string;
  fileExtension: string;
  s3Path: string;
  visibility: string;
  createdBy: any;
}

export type MessageAlertLevel = 'LOW' | 'MEDIUM' | 'HIGH' | 'INFO';

export enum FeatureType {
  ACTIVATION = 'activation',
  ADD_TRIP = 'addTrip',
  REGISTRATION = 'registration',
  VIEW_UNPAID_TRIP = 'viewUnpaidTrip',
}

export enum MessageCategories {
  Alert,
}

export type AlertMessage = {
  messageType: MessageType.ALERT_MESSAGE;
  alertLevel: MessageAlertLevel;
  backgroundUrl: string;
  createTs: number;
  dateLabel: string;
  timeLabel: string;
  contentCTAText: string;
  contentCTAUrl: string;
  contentCategory: MessageCategories;
  contentSummary: string;
  contentText: string;
  contentTitle: string;
  header: string;
  id: string;
  userId: string;
  messageId: string;
  ticketId: string;
  authorId: string;
  hyperMessageType: HyperMessageType;
  createdAt: number;
  updateAt: number;
  author: ChatUser | null;
};

export enum Visibility {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
  PRIVATE_DELETED = 'PRIVATE_DELETED',
  PUBLIC_DELETED = 'PUBLIC_DELETED',
}

export type ChatUser = {
  id: string;
  firstName: string;
  lastName: string;
  avatarUrl: string;
  agentId?: string;
  agentName?: string;
};

export type HyperMessageType =
  | 'TEXT_MESSAGE'
  | 'FILE_OBJECT'
  | 'ALERT_MESSAGE'
  | 'SYSTEM_RATING'
  | 'RATING_MESSAGE'
  | 'RISK_ALERT_MESSAGE'
  | 'SYSTEM_MESSAGE';

export type TextMessage = {
  messageType: MessageType.TEXT_MESSAGE;
  messageId: string;
  chatId: string;
  ticketId: string;
  author: ChatUser | null;
  hyperMessageType: HyperMessageType;
  createdAt: number;
  updateAt: number;
  content: string;
  displayErrorBadge?: boolean;
};

export type SystemMessage = {
  messageType: MessageType.SYSTEM_MESSAGE;
  messageId: string;
  chatId: string;
  ticketId: string;
  hyperMessageType: HyperMessageType;
  createdAt: number;
  updateAt: number;
  content: string;
  title: string;
  status: string;
  author: ChatUser | null;
};

export type OOOMessage = {
  messageId: string;
  messageType: MessageType.OUT_OF_OFFICE;
  isOutOfOffice: boolean;
  outOfOfficeHours: OutOfOfficeHours;
  emergencyNumber: string;
  createTs: number;
};

export type AttachmentMessage = {
  messageType: MessageType.ATTACHMENT;
  messageId: string;
  chatId: string;
  ticketId: string;
  author: ChatUser | null;
  hyperMessageType: HyperMessageType;
  createdAt: number;
  updateAt: number;
  content: string;
  fileName: string;
  s3Path: string;
  fileExtension: string;
  fileSize: number | null;
  deleted: boolean;
  deletedTs: string;
  deletedBy: string;
  uploadStatus?: UploadState;
  attachmentUri?: string;
};

export type Country = {
  countryCallingCode: string;
  code: string;
  currency: string;
  currencyFlag?: string;
  currencyName: string;
  longName: string;
};

export type RatingMessage = {
  messageType: MessageType.RATING;
  messageId: string;
  chatId: string;
  ticketId: string;
  author: ChatUser | null;
  hyperMessageType: HyperMessageType;
  createdAt: number;
  updateAt: number;
  tags: string[] | null;
  addTags: string[];
};

export type RiskAlertMessage = {
  messageType: MessageType.RISK_MESSAGE;
  author: ChatUser | null;
  messageId: string;
  chatId: string;
  ticketId: string;
  hyperMessageType: HyperMessageType;
  createdAt: number;
  header: string;
} & RiskAlertContent;

export type RiskAlertContent = {
  backgroundUrl: string;
  startDateUTC: number;
  startDate?: string;
  endDate?: string;
  endDateUTC: number;
  riskLevel: RiskLevelValue;
  riskAlertMessageId: string;
  title: string;
  text: string;
  countryName: string;
  countryCode: string;
  category: RiskCategory;
  advice: string;
  takesPlace: string;
  isActive: boolean;
  createdAt?: number;
  status?: boolean;
};

export type RiskLevelValue = 'EXTREME' | 'HIGH' | 'MEDIUM' | 'MODERATE' | 'LOW';

export type RiskCategory = keyof typeof RISK_ALERT_CATEGORY;
export enum RISK_ALERT_CATEGORY {
  POLITICAL_DEVELOPMENTS = 'Political Developments',
  CONFLICT_AND_TERRORISM = 'Conflict and Terrorism',
  DEMONSTRATIONS_AND_UNREST = 'Demonstrations and Unrest',
  CRIME_AND_CORRUPTION = 'Crime and Corruption',
  NATURAL_AND_ENVIRONMENTAL = 'Natural and Environmental',
  HEALTH_AND_MEDICAL = 'Health and Medical',
  TRAVEL_SAFETY_AND_DISRUPTIONS = 'Travel Safety and Disruptions',
  AVALANCHES = 'Avalanches',
  BLIZZARDS = 'Blizzards',
  DANGEROUS_WILDLIFE = 'Dangerous wildlife',
  DROUGHT = 'Drought',
  DUST_SAND_STORMS = 'Dust/Sand Storms',
  EARTHQUAKES = 'Earthquakes',
  FLOODING = 'Flooding',
  HEAT_WAVES = 'Heat Waves',
  HURRICANES_TYPHOONS_TROPICAL_CYCLONES = 'Hurricanes/Typhoons/Tropical Cyclones',
  LANDSLIDES_MUDSLIDES = 'Landslides/Mudslides',
  SMOG_HAZE_AND_POLLUTION = 'Smog/Haze and Pollution',
  SURF_RIP_CURRENTS = 'Surf/Rip currents',
  TORNADOES = 'Tornadoes',
  TSUNAMI = 'Tsunami',
  VOLCANIC_ERUPTIONS = 'Volcanic Eruptions',
  WILDFIRES = 'Wildfires',
  WINDSTORMS = 'Windstorms',
  FOG = 'Fog',
  RAINFALL = 'Rainfall',
  SECURITY_ALERT = 'Security Alert',
  THUNDERSTORMS = 'Thunderstorms',
  EXTREME_COLD = 'Extreme cold',
  UNKNOWN = 'Unknown',
}

export type Category = {
  id: string;
  name: string;
};

export type HyperMessage =
  | AlertMessage
  | TextMessage
  | AttachmentMessage
  | RatingMessage
  | SystemMessage
  | RiskAlertMessage;

export type ChatMessage = {
  author: ChatUser | null;
  dateLabel: string;
  timeLabel: string;
} & (OOOMessage | HyperMessage);

export type ExclusiveStartKey = {
  chatId: string;
  createdAt: number;
  messageId: string;
};

export interface ChatMessages {
  ids: object;
  nextToken: string | undefined | null;
  chatMessages: Array<ChatMessage>;
  exclusiveStartKey: ExclusiveStartKey | null | undefined;
}

export interface TypingIndicator {
  shouldShow: boolean;
  typingText: string | null;
}
export type TypingEvent = {
  eventType: string;
  id: string;
  isTyping: boolean;
  sender: {
    id: string;
    firstName: string;
    lastName: string;
  };
  userId: string;
  typename: string;
};

export interface GraphQLErrorParams {
  name: string;
  schema: string;
  input: object;
  appSyncError: object;
}

export interface FeatureDisabledMessage {
  title: string;
  subtitle: string;
  content: string;
}

export enum EXTERNAL_DOCUMENTS {
  PDS = 'pds',
  TMD = 'tmd',
}

export enum US_PAYMENT_TOKEN_TYPE {
  APPLE_PAY = 'ApplePay',
  GOOGLE_PAY = 'GooglePay',
  CREDIT_CARD = 'CreditCard',
  CREDIT_CARD_TOKEN = 'CreditCardToken',
}
export interface PaymentOption {
  cardNumber?: string;
  nameOnCard?: string;
  expiryDate?: string;
  type?: PAYMENT_TYPE | null;
  id?: string;
  paymentToken?: any;
  tokenType?: US_PAYMENT_TOKEN_TYPE;
}

export interface CreditCardForm {
  cardNumber: string;
  expiryDate: string;
  nameOnCard: string;
  saveForFuturePayments: boolean;
  cardType: PAYMENT_TYPE | null;
}

export interface TripCostForm {
  totalTripCost: string;
}

export type TabViewScene = {
  key: string;
  title: string | null;
  component: ReactNode;
  testProps?: TestProps;
};

export enum LocalStorageKeys {
  PUSH_TOKEN = 'PUSH_TOKEN',
  SENTIANCE_USER_CONTEXT_DATA = 'SENTIANCE_USER_CONTEXT_DATA',
  SENTIANCE_USER_CONTEXTS = 'SENTIANCE_USER_CONTEXTS',
  CODEPUSH_METADATA = 'CODEPUSH_METADATA',
  CODEPUSH_LAST_UPDATE_TIMESTAMP = 'CODEPUSH_LAST_UPDATE_TIMESTAMP',
  IS_SENTRY_ENABLED = 'IS_SENTRY_ENABLED',
  SAFETY_ALERTS_READ = 'SAFETY_ALERTS_READ',
  ACTIVATION_CODE = 'ACTIVATION_CODE',
  ACTIVATION_DATE = 'ACTIVATION_DATE',
  ACTIVATION_TOKEN = 'ACTIVATION_TOKEN',
}

export enum SIGN_UP_FLOW_TYPE {
  SAVE_QUOTE = 'SAVE_QUOTE',
  CHAT = 'CHAT',
  JOIN = 'JOIN',
}

export interface WhosTravellingForm {
  firstName: string;
  dob: string;
  lastName: string;
}

export enum ISO_TIME_STRING {
  START = 'T00:00:00.000Z',
  MID = 'T12:00:00.000Z',
  END = 'T23:59:59.000Z',
}

export type SelectedDuration =
  | {
      startDate: string;
      endDate: string;
    }
  | undefined;

export interface BoostArgument {
  boostId: string;
  isRefreshingTrip?: boolean;
}

export interface UpdateBoostArgument extends BoostArgument {
  updatePayload: Partial<Boost>;
}

export interface ToggleBoostStatusArgument extends BoostArgument {
  status: BOOST_UI_STATUS | null;
}

export interface AddSpecifiedItemToBoostArgument extends BoostArgument {
  specifiedBoostItemToAdd: SpecifiedBoostItem;
}

export interface RemoveSpecifiedItemFromBoostArgument extends BoostArgument {
  itemIdToBeRemoved: string;
}

export interface SecondaryTravellerValidationMessages {
  sortKey: string;
  message: string;
}

export type ListItemVariants = 'chevron' | 'edit' | 'remove' | 'noIcon';
export type BoostModalType = 'addSpecifiedItems' | 'extraCancellation' | 'payPerDayCalendar';

export type ButtonVariant =
  | 'mint'
  | 'snow'
  | 'charcoal'
  | 'white'
  | 'cherry'
  | 'sunset'
  | 'cabo'
  | 'fuji'
  | 'outline';

export type BoostPriceType = 'boostCard' | 'boostModal' | 'boostDetails';

export type UpdateTripStatusArgument = {
  status?: TRIP_STATUS;
  policyStatus?: 'ERROR' | 'NORMAL';
  policyNumber?: string | null;
};

export type FormOnChange = (...event: any[]) => void;

export enum PLATFORM {
  WEB = 'web',
  APP = 'app',
  WIDGET = 'qw',
}

export type TravellersForm = {
  primaryTravellerDOB: string;
  primaryTravellerIsResident: boolean;
  secondaryTravellers?: Array<SecondaryTraveller>;
};

export type DestinationsForm = {
  destinations: TripDestination[];
};

export type TripDatesForm = {
  tripDates: {
    startDate: string;
    endDate: string;
  };
};

export type GetQuoteForm = Partial<
  TravellersForm & TripCostForm & DestinationsForm & TripDatesForm
>;

export type AnalyticsCommonProps = {
  platform: PLATFORM;
  region: string;
  state?: string;
  quoteId?: string;
  quotePartnerName?: string;
  anonymousId?: string;
  userId?: string;
  deviceId?: string;
};

export interface ManualAddressFormInterface {
  addressLine1?: string;
  addressLine2?: string;
  addressCity?: string;
  addressState?: string;
  addressPostCode?: string;
}

export type GuestDetailsForm = GuestDetails &
  ManualAddressFormInterface & {
    isResident?: boolean;
  };

export interface SelectOption {
  id: string;
  label: string;
  value: unknown;
}

export interface PhoneNumberValidationRule {
  phoneNumberMask?: string;
  phoneNumberRegex: string;
  phoneNumberRegexMessage: string;
}

export type TripCardType = 'homeScreen' | 'myTrips';

export enum BOOST_CODE {
  LUGGAGE = 'LBCP', // This is deprecated. LBCP and LPCP are removed from domestic plan and international on F3B and F4B
  MOTORCYCLE = 'MTCL4', // Previously was MTCL3
  PRE_TRIP_CANCELLATION = 'USTC',
  SNOW_SPORTS = 'SSPFREE', // Previously SSFREE
  CRUISE_COVER = 'CRSF',
  GADGET_COVER = 'GADGFREE',
  EMC = 'EMC',
}

export interface PrimaryTravellerEligibleParams {
  dob: DateTime | string | undefined | null;
  maxAge: number;
  minAge: number;
  regionCode: RegionCode;
}

export interface TripDurationParams {
  startDate: string;
  endDate: string;
  regionCode: RegionCode;
  duration: DATE_TIME_DURATION;
}

export type PreviouslySelectedCover = {
  selectedCover?: number;
  price?: number;
  isReverting?: boolean;
};

export type BoostUpsellType = 'motorcycle';

export type AWSInit = { [p: string]: any };

export interface BaseListItemType {
  id: string | number;
  title?: string;
  description?: string;
  icon?: any;
  value?: boolean | string;
}

export enum CLAIM_TYPE {
  MY_HEALTH = 'MY_HEALTH',
  MY_TRIP = 'MY_TRIP',
  MY_STUFF = 'MY_STUFF',
  SOMETHING_ELSE = 'SOMETHING_ELSE',
}

export enum CLAIM_SUB_CATEGORY {
  COVID_19_OR_INJURY_FOOD_POISONING = 'COVID_19_OR_INJURY_FOOD_POISONING',
  OTHER = 'OTHER',
  IN_CAR_ACCIDENT = 'IN_CAR_ACCIDENT',
  SOMETHING_ELSE = 'SOMETHING_ELSE',
}

export type KeyMatcher<T> = {
  [K in keyof T]: T[K] extends object ? KeyMatcher<T[K]> : ((value: T[K]) => boolean) | undefined;
};

export enum CLAIM_EVIDENCE_UPLOAD_TYPE {
  // MY STUFF
  LUGGAGE_DELAYED_TRANSIT = 'LUGGAGE_DELAYED_TRANSIT', // 1.1
  ITEM_DAMAGED_NOT_BY_TRANSPORT_PROVIDER = 'ITEM_DAMAGED_NOT_BY_TRANSPORT_PROVIDER', // 1.2
  ITEM_DAMAGED_BY_TRANSPORT_PROVIDER = 'ITEM_DAMAGED_BY_TRANSPORT_PROVIDER', // 1.2A
  LOST_OR_STOLEN_PHONE_NOT_BY_TRANSPORT_PROVIDER = 'LOST_OR_STOLEN_PHONE_NOT_BY_TRANSPORT_PROVIDER', // 1.3
  LOST_OR_STOLEN_PHONE_BY_TRANSPORT_PROVIDER = 'LOST_OR_STOLEN_PHONE_BY_TRANSPORT_PROVIDER', // 1.3A
  LOST_OR_STOLEN_MONEY_NOT_BY_TRANSPORT_PROVIDER = 'LOST_OR_STOLEN_MONEY_NOT_BY_TRANSPORT_PROVIDER', // 1.4
  LOST_OR_STOLEN_MONEY_BY_TRANSPORT_PROVIDER = 'LOST_OR_STOLEN_MONEY_BY_TRANSPORT_PROVIDER', // 1.4A
  LOST_OR_STOLEN_ITEM_NOT_BY_TRANSPORT_PROVIDER = 'LOST_OR_STOLEN_ITEM_NOT_BY_TRANSPORT_PROVIDER', // 1.5
  LOST_OR_STOLEN_ITEM_BY_TRANSPORT_PROVIDER = 'LOST_OR_STOLEN_ITEM_BY_TRANSPORT_PROVIDER', // 1.5A

  // MY_HEALTH
  REQUIRE_HOSPITAL_ADMISSION_NO_AMBULANCE_NO_EMC = 'REQUIRE_HOSPITAL_ADMISSION_NO_AMBULANCE_NO_EMC', // 2.1
  REQUIRE_HOSPITAL_ADMISSION_AND_AMBULANCE_NO_EMC = 'REQUIRE_HOSPITAL_ADMISSION_AND_AMBULANCE_NO_EMC', // 2.2
  // EXISTING MEDICAL CONDITION 2.3 is out of scope and is straight to email
  NO_HOSPITAL_ADMISSION_NO_AMBULANCE_NO_EMC = 'NO_HOSPITAL_ADMISSION_NO_AMBULANCE_NO_EMC', // 2.4
  NO_HOSPITAL_ADMISSION_REQUIRE_AMBULANCE_NO_EMC = 'NO_HOSPITAL_ADMISSION_REQUIRE_AMBULANCE_NO_EMC', // 2.5
  // NO_HOSPITAL_ADMISSION_NO_AMBULANCE_REQUIRE_EMC 2.6 is out of scope
  // NO_HOSPITAL_ADMISSION_REQUIRE_AMBULANCE_AND_EMC 2.7 is out of scope
  MY_HEALTH_OTHER = 'MY_HEALTH_OTHER', // Unhappy path (same as trip) but there is no scenario

  // SOMETHING_ELSE
  RENTAL_CAR_ACCIDENT_NO_VEHICLES_INVOLVED = 'RENTAL_CAR_ACCIDENT_NO_VEHICLES', // 3.1
  RENTAL_CAR_ACCIDENT_VEHICLES_INVOLVED = 'RENTAL_CAR_ACCIDENT_VEHICLES_INVOLVED', // 3.2
  SOMETHING_ELSE_OTHER = 'SOMETHING_ELSE_OTHER', // 3.3

  // MY TRIP
  MY_TRIP_OTHER = 'MY_TRIP_OTHER', // UNHAPPY PATH
}

export type COVID_CONSENT_LIST_ITEM = 'COVERED' | 'NOTCOVERED';

export type HeightVariant = 'small' | 'medium' | 'large';

export type AppButtonVariant = ButtonVariant | 'cherry';

export interface StoredUserContextEvent {
  time: string;
  userContext: {
    transportMode: string | null;
    startTime: string;
    endTime: string | null;
    type: string;
    isSegmentEventSent: boolean;
  };
}

export interface SentianceUserContextTripData {
  tripId: string;
  startDate: string;
  endDate: string;
  isMotorcycleUpsellShown?: boolean;
}

export interface StoredSentianceUserContextData {
  events: StoredUserContextEvent[];
  errors: string[];
  paidTripsWithoutMotorcycleBoost: SentianceUserContextTripData[];
  counts: { [key: string]: number };
}

export type ViewportSize = 'sm' | 'md' | 'lg' | 'xl';

export type FreelyPaymentMethod =
  | 'GooglePay'
  | 'ApplePay'
  | 'Mastercard'
  | 'Visa'
  | 'Amex'
  | 'Discover'
  | 'AfterPay';

export type CreateQuoteResponse = {
  quote: {
    quoteId: string;
    market: RegionCode;
  };
};

export type AutoDiscountCode = {
  type: 'referralDiscount' | 'discountedDestination' | 'group' | 'couple';
  discountCode: string;
  discountAmount: number;
  title?: string;
  content?: string;
  isEnabled: boolean;
  rules: {
    maxReferralCount?: number;
    selectedCountryCodes?: string[];
    minNoOfTravellers?: number;
  };
};

export type TimeOutErrorMessage = 'RA Timeout' | 'Penguin Timeout';

export type ParticipantPayload = {
  id: string;
  firstName?: string;
  lastName?: string;
  referralCount: number;
  monthlyReferralCount: number;
  shareUrl: string;
  vanityKeys: string[];
};

export type EmcTextItem = {
  type: 'text' | 'number-list' | 'bullet-list' | 'bold-text';
  text: string;
  listNumber?: number;
  isMarkdown?: boolean;
  className?: string;
};

export type EmcInclusionItem = {
  type: 'table';
  name: string;
  items: Array<EmcTextItem>;
};

export type EmcAccordionContentItem = Array<EmcTextItem | EmcInclusionItem>;

export type EmcAccordionItem = {
  title: string;
  content: EmcAccordionContentItem;
};

export type ScreeningQuestionErrorType = 'hasSelectedYes' | undefined;

export type ScreeningQuestion = {
  question: string;
  hasSelectedNo?: boolean;
  errorType?: ScreeningQuestionErrorType;
};

export type ScreeningQuestions = ScreeningQuestion[];

export type EMCEmailAssessment = {
  name: string;
  email: string;
};

export type EMCEmailAssessmentRequest = {
  boostId: string;
  assessmentNumber: string;
  region: Region;
  isAuthenticated?: boolean;
} & EMCEmailAssessment;
